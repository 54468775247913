import React, { useState, useEffect } from "react";
import {
  ContactComponent,
  TitleComponent,
  QuestionsComponent,
  Section,
} from "../../components";
import "./styles.scss";
import { translate } from "../../translations/localization";
import { connect } from "react-redux";
import { sendEmail } from "../../actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Clients = ({ sendEmail }) => {
  const [fullName, setFullName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    "Please wait a few seconds."
  );
  const [success, setSuccess] = useState(false);
  const [selected, setSelected] = useState(0);
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        let newLoadingMessage = loadingMessage + ".";
        if (newLoadingMessage === "Please wait a few seconds.....") {
          newLoadingMessage = "Please wait a few seconds.";
        }
        setLoadingMessage(newLoadingMessage);
      }, 500);
    }
  }, [loading, loadingMessage]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  function validateEmail() {
    const regex =
      /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (regex.test(email) === false) {
      return false;
    }
    return true;
  }
  return (
    <>
      <Section
        title={translate("clients1-1")}
        fullwidth
        image={`url(${require("../../assets/images/business-clinets-section-1.png")})`}
        titleMobile={isMobileVersion === 1 && true}
        maxHeight={isMobileVersion === 1 && 35}
        renderContent={() => {
          return <p className={`${isMobileVersion === 1 ? 'text-info' : ''}`}>{translate("clients1-2")}</p>;
        }}
      />
      <Section
        title={translate("clients2-1")}
        invert
        right={isMobileVersion === 0 && true}
        height={isMobileVersion === 1 && 115}
        //invert = {isMobileVersion === 0 && true}
        style={isMobileVersion === 1 && { marginTop: "5%", marginLeft: "5%" }}
        backgroundWhite={isMobileVersion === 1 && true}
        titleMobile={isMobileVersion === 1 && true}
        image={`url(${require("../../assets/images/business-clinets-section-2.png")})`}
        renderContent={() => {
          return (
            <>
              <p>{translate("clients2-2")}</p>
              <div
                className="inner-charging-wrapper"
                style={{ marginTop: "-1%", marginBottom: "2%" }}
              >
                <div className={`flexx-container`}>
                  <div className="point-and-text">
                    <div
                      className={`square`}
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    />
                    <h6
                      className={`tabb-title ${isMobileVersion === 1 && "mobile"
                        }`}
                    >
                      {translate("clients2-3")}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="inner-wrapper" style={{ marginBottom: "2%" }}>
                <div className={`flexx-container`}>
                  <div className="point-and-text">
                    <div
                      className={`square`}
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    />
                    <h6
                      className={`tabb-title ${isMobileVersion === 1 && "mobile"
                        }`}
                    >
                      {translate("clients2-4")}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="inner-wrapper" style={{ marginBottom: "2%" }}>
                <div className={`flexx-container`}>
                  <div className="point-and-text">
                    <div
                      className={`square`}
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    />
                    <h6
                      className={`tabb-title ${isMobileVersion === 1 && "mobile"
                        }`}
                    >
                      {translate("clients2-5")}
                    </h6>
                  </div>
                </div>
              </div>
              <p style={{ marginTop: "5%" }}>{translate("clients2-6")}</p>
            </>
          );
        }}
      />
      <Section
        title={translate("clients3-1")}
        height={isMobileVersion === 1 && 125}
        image={`url(${require("../../assets/images/business-clinets-section-3-min.png")})`}
        titleMobile={isMobileVersion === 1 && true}
        renderContent={() => {
          return (
            <>
              <p>{translate("clients3-2")}</p>
              <div
                className="inner-charging-wrapper"
                style={{ marginTop: "-1%", marginBottom: "2%" }}
              >
                <div className={`flexx-container`}>
                  <div className="point-and-text">
                    <div
                      className={`square`}
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        width: "45px",
                      }}
                    />
                    <h6
                      className={`tabb-title ${isMobileVersion === 1 && "mobile"
                        }`}
                    >
                      {translate("clients3-3")}{" "}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="inner-wrapper" style={{ marginBottom: "2%" }}>
                <div className={`flexx-container`}>
                  <div className="point-and-text">
                    <div
                      className={`square`}
                      style={{
                        backgroundRepeat: "no-repeat",
                        width: "45px",
                        backgroundSize: "cover",
                      }}
                    />
                    <h6
                      className={`tabb-title ${isMobileVersion === 1 && "mobile"
                        }`}
                    >
                      {translate("clients3-4")}{" "}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="inner-wrapper" style={{ marginBottom: "2%" }}>
                <div className={`flexx-container`}>
                  <div className="point-and-text">
                    <div
                      className={`square`}
                      style={{
                        backgroundRepeat: "no-repeat",
                        marginRight: "1%",
                        backgroundSize: "cover",
                        width: "45px",
                      }}
                    />
                    <h6
                      className={`tabb-title ${isMobileVersion === 1 && "mobile"
                        }`}
                    >
                      {translate("clients3-5")}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="inner-wrapper" style={{ marginBottom: "2%" }}>
                <div className={`flexx-container`}>
                  <div className="point-and-text">
                    <div
                      className={`square`}
                      style={{
                        backgroundRepeat: "no-repeat",
                        marginRight: "1%",
                        backgroundSize: "cover",
                        width: "45px",
                      }}
                    />
                    <h6
                      className={`tabb-title ${isMobileVersion === 1 && "mobile"
                        }`}
                    >
                      {translate("clients3-6")}
                    </h6>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      />
      <Section
        title={translate("clients4-1")}
        invert
        right={isMobileVersion === 0 && true}
        image={`url(${require("../../assets/images/business-clinets-section-5.png")})`}
        height={isMobileVersion === 1 && 120}
        titleMobile={isMobileVersion === 1 && true}
        renderContent={() => {
          return (
            <>
              <div
                className="inner-charging-wrapper"
                style={{ marginTop: "3%", marginBottom: "2%" }}
              >
                <div className={`flexx-container`}>
                  <div className="point-and-text">
                    <div
                      className={`square`}
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    />
                    <h6
                      className={`tabb-title ${isMobileVersion === 1 && "mobile"
                        }`}
                    >
                      {translate("clients4-2")}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="inner-wrapper" style={{ marginBottom: "2%" }}>
                <div className={`flexx-container`}>
                  <div className="point-and-text">
                    <div
                      className={`square`}
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        marginRight: "1%",
                      }}
                    />
                    <h6
                      className={`tabb-title ${isMobileVersion === 1 && "mobile"
                        }`}
                    >
                      {translate("clients4-3")}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="inner-wrapper" style={{ marginBottom: "2%" }}>
                <div className={`flexx-container`}>
                  <div className="point-and-text">
                    <div
                      className={`square`}
                      style={{
                        backgroundRepeat: "no-repeat",
                        marginRight: "1%",
                        backgroundSize: "cover",
                      }}
                    />
                    <h6
                      className={`tabb-title ${isMobileVersion === 1 && "mobile"
                        }`}
                    >
                      {translate("clients4-4")}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="inner-wrapper" style={{ marginBottom: "2%" }}>
                <div className={`flexx-container`}>
                  <div className="point-and-text">
                    <div
                      className={`square`}
                      style={{
                        backgroundRepeat: "no-repeat",
                        marginRight: "1%",
                        backgroundSize: "cover",
                      }}
                    />
                    <h6
                      className={`tabb-title ${isMobileVersion === 1 && "mobile"
                        }`}
                    >
                      {translate("clients4-5")}
                    </h6>
                  </div>
                </div>
              </div>
              <p style={{ marginTop: "5%" }}>{translate("clients4-6")}</p>
              <div
                className={`button-right mobile`}
                style={{ marginTop: "0%", justifyContent: "flex-start" }}
              >
                <div
                  className="button-container"
                  style={{
                    fontSize: "16px",
                    marginLeft: isMobileVersion === 1 && "5%",
                    marginBottom: isMobileVersion === 1 && "5%",
                  }}
                  onClick={() => {
                    window.scrollTo({
                      top: window.innerHeight * 6.2,
                      behavior: "smooth",
                    });
                  }}
                >
                  {translate("contactUs")}
                </div>
              </div>
            </>
          );
        }}
      />
      <Section
        title={"DASHBOARD"}
        image={`url(${require("../../assets/images/business-clinets-section-3.png")})`}
        titleMobile={isMobileVersion === 1 && true}
        height={isMobileVersion === 0 && 101}
        renderContent={() => {
          return (
            <>
              <p style={{ fontSize: isMobileVersion === 1 ? "16px" : "18px" }}>
                {translate("clients5-1")}.
              </p>
              <div className="point-and-text">
                <div className={`squaree`} />
                <h6
                  className={`tabb-title`}
                  style={{ fontSize: isMobileVersion === 1 ? "16px" : "18px" }}
                >
                  {translate("clients5-2")}
                </h6>
              </div>
              <div className="point-and-text" style={{ marginTop: "5%" }}>
                <div className={`squaree`} />
                <h6
                  className={`tabb-title`}
                  style={{ fontSize: isMobileVersion === 1 ? "16px" : "18px" }}
                >
                  {translate("clients5-3")}
                </h6>
              </div>
              <div className="point-and-text" style={{ marginTop: "5%" }}>
                <div className={`squaree`} />
                <h6
                  className={`tabb-title`}
                  style={{ fontSize: isMobileVersion === 1 ? "16px" : "18px" }}
                >
                  {translate("clients5-4")}
                </h6>
              </div>
            </>
          );
        }}
      />
      <Section
        title={"DASHBOARD + MOBILE APP"}
        invert={isMobileVersion === 0 && true}
        backgroundWhite={isMobileVersion === 1 && true}
        titleMobile={isMobileVersion === 1 && true}
        right={isMobileVersion === 0 && true}
        image={`url(${require("../../assets/images/business-clinets-section-4.png")})`}
        renderContent={() => {
          return (
            <>
              <p>{translate("clients6-1")}</p>
            </>
          );
        }}
      />

      {isMobileVersion === 0 ? (
        selected === 0 ? (
          <Section
            title={translate("clients7-1")}
            map={selected === 0 ? 1 : 0}
            X={42.6624857000924}
            Y={23.380830984583703}
            image={""}
            fullInner
            height={100}
            renderContent={() => {
              return (
                <>
                  <h2 style={{ fontSize: "22px" }}>
                    {translate("clients7-2")}
                  </h2>
                  <div className="upper-arrow-container">
                    <div
                      className={`inner-arrow ${selected === 0 ? "sel" : ""}`}
                    />
                    <div
                      className={`inner-arrow--1 ${selected === 1 ? "sel" : ""
                        }`}
                    />
                  </div>
                  <div className="inner-contact-tabs-container">
                    <div
                      className="first-element"
                      onClick={() => {
                        setSelected(0);
                      }}
                    >
                      <div
                        className={`inner-icon ${selected === 0 ? "selected" : ""
                          }`}
                      />
                    </div>
                    <div
                      className="second-element"
                      onClick={() => {
                        setSelected(1);
                      }}
                    >
                      <div
                        className={`inner-icon-1 ${selected === 1 ? "sell" : ""
                          }`}
                        id={"second"}
                      />
                    </div>
                  </div>
                  <div className="info-container" style={{ marginTop: "7%" }}>
                    <div className="inner-info-container">
                      <p style={{ fontSize: "15px" }}>
                        <b>{translate("clients7-3")}</b>
                      </p>
                      <p style={{ marginTop: "-3%" }}>
                        office@winkcharging.com
                      </p>
                    </div>
                    <div className="inner-info-container">
                      <p style={{ fontSize: "15px" }}>
                        <b>{translate("clients7-4")}</b>
                      </p>
                      {/* <p style={{ marginTop: "-3%" }}>+359 2 980 10 59</p> */}
                      <p style={{ marginTop: "-3%" }}>+359 887 141 117</p>
                    </div>
                  </div>
                  <div className="info-container" style={{ marginTop: "7%" }}>
                    <div className="inner-info-container">
                      <p style={{ fontSize: "15px" }}>
                        <b>{translate("clients7-5")}</b>
                      </p>
                      <p style={{ marginTop: "-2%" }}>{translate("address1")}</p>
                    </div>
                    
                    <div className="inner-info-container">
                      <p style={{ fontSize: "15px" }}>
                        <b>{translate("clients7-6")}</b>
                      </p>
                      <div className="flex">
                        <div className="icon facebook" onClick={() => window.open("https://www.facebook.com/profile.php?id=100082523756187")} />
                        <div className="icon instagram" onClick={() => window.open("https://www.instagram.com/wink.charging?igsh=bTVkbXZtZzkxMXBz")} />
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          />
        ) : (
          <div className={`contact-component-container`}>
            <div className="left-container" style={{ width: "50%" }} />
            <div className="right-container" style={{ width: "50%" }}>
              <div
                className="inner-container"
                style={{ height: "85%", width: "90%" }}
              >
                <TitleComponent title={translate("clients7-1")} />
                <h2 style={{ textAlign: "left", fontSize: "22px" }}>
                  {translate("clients7-2")}
                </h2>
                <div className="upper-arrow-container">
                  <div
                    className={`inner-arrow-1 ${selected === 1 ? "sel" : ""}`}
                  />
                </div>
                <div className="inner-contact-tabs-container">
                  <div
                    className="first-element"
                    onClick={() => {
                      setSelected(0);
                    }}
                  >
                    <div
                      className={`inner-icon ${selected === 0 ? "selected" : ""
                        }`}
                    />
                  </div>
                  <div
                    className="second-element"
                    onClick={() => {
                      setSelected(1);
                    }}
                  >
                    <div
                      className={`inner-icon-1 ${selected === 1 ? "sell" : ""}`}
                      id="second"
                    />
                  </div>
                </div>
                <div className="flex-container">
                  <div className="form-input-wrapper">
                    <input
                      required
                      className="form-input"
                      type="text"
                      value={fullName}
                      placeholder={translate("contactForm1")}
                      onChange={(event) => setFullName(event.target.value)}
                    />
                  </div>
                  <div className="form-input-wrapper">
                    <input
                      required
                      className="form-input"
                      type="text"
                      value={company}
                      placeholder={translate("contactForm2")}
                      onChange={(event) => setCompany(event.target.value)}
                    />
                  </div>
                </div>
                <div className="flex-container">
                  <div className="form-input-wrapper">
                    <input
                      required
                      className="form-input"
                      type="text"
                      value={email}
                      placeholder={translate("contactForm3")}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                  <div className="form-input-wrapper">
                    <input
                      required
                      className="form-input"
                      type="text"
                      value={phone}
                      placeholder={translate("contactForm4")}
                      onChange={(event) => setPhone(event.target.value)}
                    />
                  </div>
                </div>
                <div className="flex-end">
                  <div className="flex-container">
                    <div className="form-textarea-wrapper">
                      <textarea
                        required
                        value={message}
                        placeholder={translate("contactForm5")}
                        style={{ width: "40vw" }}
                        onChange={(event) => setMessage(event.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="checkboxes"></div>
                <div className="button-right">
                  <div
                    className="button-container"
                    onClick={() => {
                      if (!loading && validateEmail()) {
                        document.getElementById("error").style.display = "none";
                        setLoading(true);
                        setSuccess(false);
                        const payload = {
                          system: "wink",
                          sender: fullName,
                          company: company,
                          email: email,
                          phoneNumber: phone,
                          message: message,
                          onSuccess: () => {
                            setLoading(false);
                            setSuccess(true);
                            setFullName("");
                            setCompany("");
                            setEmail("");
                            setPhone("");
                            setMessage("");
                          },
                          onError: (error) => {
                            setLoading(false);
                            toast(error);
                          },
                        };
                        sendEmail(payload);
                      } else
                        document.getElementById("error").style.display =
                          "block";
                    }}
                  >
                    {translate("send")}
                  </div>
                </div>
                <p style={{ display: "none" }} id="error">
                  {translate("contactForm8")}
                </p>
                {loading && <p>{loadingMessage}</p>}
                {success && <p>{translate("contactForm9")}</p>}
              </div>
            </div>
          </div>
        )
      ) : (
        <>
          <TitleComponent title={translate("clients7-1")} fullLine mobile />
          <h6
            style={{
              width: "90%",
              fontSize: "17px",
              marginLeft: "5%",
              height: "3%",
              marginTop: "2.5%",
              lineHeight: "1.5em",
            }}
          >
            {translate("clients7-2")}
          </h6>
          <div className="mobile-tab">
            <div className="mobile-icon" />
          </div>
          <Section
            map={1}
            mobile={isMobileVersion}
            title={""}
            X={42.6626879801289}
            Y={23.38031102658445}
            height={70}
            renderContent={() => {
              return (
                <>
                  <div className="double-info" style={{ marginTop: "10%" }}>
                    <p className="info-title">
                      <b>{translate("clients7-3")}:</b>
                    </p>
                    <p className="second-field">office@winkcharging.com</p>
                  </div>
                  <div className="double-info">
                    <p className="info-title">
                      <b>{translate("clients7-4")}:</b>
                    </p>
                    {/* <p className="second-field">+359 2 980 10 59</p> */}
                    <p className="second-field">+359 887 141 117</p>
                  </div>
                  <div className="double-info">
                    <p className="info-title">
                      <b>{translate("clients7-5")}:</b>
                    </p>
                    <p className="second-field">{translate("address1")}</p>
                  </div>
                  <div className="double-info">
                    <p className="info-title">
                      <b>{translate("clients7-6")}</b>
                    </p>
                    <div className="flex">
                      <div className="icon facebook" onClick={() => window.open("https://www.facebook.com/profile.php?id=100082523756187")} />
                      <div className="icon instagram" onClick={() => window.open("https://www.instagram.com/wink.charging?igsh=bTVkbXZtZzkxMXBz")} />
                    </div>
                  </div>
                </>
              );
            }}
          />
          <div className="mobile-tab">
            <div className="mobile-icon contact" />
          </div>
          <ContactComponent mobile={1} />
        </>
      )}

      <QuestionsComponent bullets clients={isMobileVersion === 1 && true} />
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  sendEmail: (payload) => dispatch(sendEmail(payload)),
});
export default connect(null, mapDispatchToProps)(Clients);

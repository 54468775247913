import React from "react";
import "./styles.scss";

function TitleComponent({ title, small, mobileSpecial, removeLine, titleId, smallerTitle, marginleft, fullwidth, leftfullwidth, fullLine, style, mobile, marginBottom, custom }) {
  return (
    <div
      className={`title-container ${mobileSpecial && "mobile-special"} ${small && "small"} 
      ${fullwidth && "full"} ${leftfullwidth && "leftfull"} ${fullLine && "fullLine"}  ${mobile && "mobile"}  ${custom}`}
      style={{ marginLeft: `${marginleft}%`, marginBottom: `${marginBottom}%` }}
    >
      {smallerTitle ? (
        <h3 id={`${titleId}`} style={{ textTransform: "uppercase", }}>
          <b>{title}</b>
        </h3>
      ) : (
        <h1 id={`${titleId}`} style={{ textTransform: "uppercase" }}>
          {title}
        </h1>
      )}
      {removeLine ? "" : <div className="title-line" />}
    </div>
  );
}

export default TitleComponent;

export const MapTypes = {
    GET_LOCATIONS: 'map/GET_LOCATIONS',
    GET_LOCATIONS_SUCCESS: 'map/GET_LOCATIONS_SUCCESS',
    GET_SELECTED_LOCATION: 'map/GET_SELECTED_LOCATION',
    GET_SELECTED_LOCATION_SUCCESS: 'map/GET_SELECTED_LOCATION_SUCCESS'
  }
  
  export const getLocations = (payload) => ({
    type: MapTypes.GET_LOCATIONS,
    payload
  })
  
  export const getSelectedLocation = (payload) => ({
    type: MapTypes.GET_SELECTED_LOCATION,
    payload
  })
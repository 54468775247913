import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { ajax } from "rxjs/ajax";
import { articlesTypes } from "../actions";
import { API } from "../config/settings";
import { basicAuth } from "../config/settings";

export const getArticles = (action$) => action$.pipe(
    ofType(articlesTypes.GET_ARTICLES),
    switchMap(({ payload, onSuccess }) => ajax({
        url: `${API}admin/article/list`,
        method: "POST",
        headers: basicAuth,
        body: JSON.stringify(payload),
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            obs.next({ type: articlesTypes.SET_ARTICLES_FIELDS, payload: { ...response.payload } })
            if (onSuccess) onSuccess(response)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.log(err)
            obs.complete()
        }))
    ))
)

export const getArticle = (action$) => action$.pipe(
    ofType(articlesTypes.GET_ARTICLE),
    switchMap(({ payload, onSuccess }) => ajax({
        url: `${API}admin/article/${payload}`,
        method: "GET",
        headers: basicAuth,
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            obs.next({ type: articlesTypes.SET_ARTICLES_FIELDS, payload: { article: response.payload } })
            if (onSuccess) onSuccess(response.payload)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.log(err)
            obs.complete()
        }))
    ))
)
import React from "react";
import "./styles.scss";
import { history } from "../../config/stores";
import { useLocation } from "react-router-dom";
import { translate } from "../../translations/localization";


const Footer = () => {
  const location = useLocation().pathname;
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;

  return (<>
    {isMobileVersion === 1 ? <>
      <div className="mobile-full-footer-container">
        <div className="mobile-footer-element" style={{marginTop:"5%"}}>
          <div className="mobile-footer-logo"/>
          <div className="mobile-footer-element no-border">
        </div> 
        </div>
        <div className="mobile-footer-element">
              <div className="footer-inner-element contact">
                <p id="mobile-bigger-info" style={{fontSize:"18px",cursor:"pointer"}} onClick={()=>{
                  history.push("/charging")
                }}>{translate("header1")}</p>
                <p id="mobile-bigger-info" style={{fontSize:"18px",cursor:"pointer"}} onClick={()=>{
                  history.push("/app")
                }}>{translate("header2")}</p>
                <p id="mobile-bigger-info" style={{fontSize:"18px",cursor:"pointer"}} onClick={()=>{
                  history.push("/clients")
                }}>{translate("header3")}</p>
                <p id="mobile-bigger-info" style={{fontSize:"18px",cursor:"pointer"}} onClick={()=>{
                  history.push("/about")
                }}>{translate("header4")}</p>
            </div>
          </div> 
        <div className="mobile-footer-element">
            <p id="mobile-bigger-info"><b>{translate("contactUs")}</b></p>
            <div className="mobile-contact-container">
              <p className="mobile-contact-info"><b>E:</b> office@winkcharging.com</p>
              {/* <p className="mobile-contact-info"><b>T:</b> +359 2 980 10 59</p> */}
              <p className="mobile-contact-info"><b>T:</b> +359 887 141 117</p>
              <p className="mobile-contact-info"><b>A:</b> Бул. Джеймс Баучер 103, ет.1, оф.3</p>
            </div>
        </div>
        <div className="mobile-footer-element">
          <div className="mobile-footer-inner-element">
                <p>{translate("download")}</p>
                <div className="outer-mobile-flex-container">
                  <div className="mobile-flex-container">
                    <div
                      className="mobile-app-store white"
                      onClick={() =>
                        window.open(
                          "https://apps.apple.com/app/wink-charging/id1615549467"
                        )
                      }
                    />
                    <div
                      className="mobile-google-play white"
                      onClick={() =>
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.winkchargingapp&hl=bg&gl=US"
                        )
                      }
                    />
                  </div>
                  <div style={{borderTop:"1px solid white",width:"102%",marginLeft:"-1%"}}>
                    <p id="bigger" onClick={() => {window.open("https://immotech-web.s3.eu-central-1.amazonaws.com/images/privacy.pdf")}}>{translate("contactForm7")}</p>
                    <p id="bigger" style={{marginTop:"5%"}} onClick={() => {window.open("https://immotech-web.s3.eu-central-1.amazonaws.com/images/terms.pdf")}}>{translate("contactForm6")}</p>
                  </div>
                  
                </div>
              </div>
        </div>
      </div >
      </>
      :
<div className={`full-footer-container ${location === "/" && "home"} ${location === "/charging" && "home"} ${location === "/clients" && "home"} ${location === "/app" && "home"}`}>
  <div className="upper-footer-container">
    <div className="footer-element column" style={{ marginBottom: "2%", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <div className="footer-logo" />

    </div>
    <div className="footer-element" style={{ height: "150px", alignItems: "center" }}>
      <div className="footer-inner-element">
        <p id="bigger-info semibold" style={{ fontSize: "16px", cursor: "pointer" }} onClick={() => {
          history.push("/charging")
        }}>{translate("header1")}</p>
        <p id="bigger-info semibold" style={{ fontSize: "16px", cursor: "pointer" }} onClick={() => { history.push("./app") }}>{translate("header2")}</p>
        <p id="bigger-info semibold" style={{ fontSize: "16px", cursor: "pointer" }} onClick={() => {
          history.push("/clients")
        }}>{translate("header3")}</p>
        <p id="bigger-info semibold" style={{ fontSize: "16px", cursor: "pointer" }} onClick={() => {
          history.push("/about")
        }}>{translate("header4")}</p>
      </div>
    </div>
    <div className="footer-element" style={{ height: "150px" }}>
      <div className="footer-inner-element contact" >
        <p id="bigger-info"><b>{translate("contactUs")}</b></p>
        <div className="contact-container">
          <p className="contact-info" style={{ height: "15%" }} href="mailto:team@fidweb.net" id="email"><b>E:</b> office@winkcharging.com</p>
          <p className="contact-info" style={{ height: "15%" }} id="phone" href="tel:+359 887 141 117"><b>T:</b> +359 887 141 117</p>
          <p className="contact-info" style={{ height: "15%" }} id="phone" href="tel:+359 887 141 117"><b>A:</b> Бул. Джеймс Баучер 103, ет.1, оф.3</p>
        </div>
      </div>
    </div>

    <div className="footer-element" style={{ height: "150px", display: "flex", alignItems: "center", flexDirection: "column" }}>
      <p className="bigger-info semibold" style={{ width: "100%", textAlign: "left", marginLeft: "10%", fontSize: "17px" }}>{translate("download")}</p>
      <div className="footer-inner-element-download">
        <div className="footer-apple" onClick={() => { window.open("https://apps.apple.com/app/wink-charging/id1615549467") }} />
        <div className="footer-google" onClick={() => { window.open("https://play.google.com/store/apps/details?id=com.winkchargingapp&hl=bg&gl=US") }} />
      </div>
    </div>
  </div>
  <div className="lower-footer-container">
    <p style={{ cursor: "pointer", marginLeft: "30%", fontSize: "15px" }} onClick={() => { window.open("https://immotech-web.s3.eu-central-1.amazonaws.com/images/privacy.pdf") }}>{translate("contactForm7")}</p>
    <p style={{ cursor: "pointer", marginLeft: "10%", fontSize: "15px" }} onClick={() => { window.open("https://immotech-web.s3.eu-central-1.amazonaws.com/images/terms.pdf") }}>{translate("contactForm6")}</p>
  </div>
</div>
    }
      </>
            );
};

export default Footer;

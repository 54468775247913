import React, { useState } from "react";
import "./styles.scss";
import { Tabs } from "../../components";
import { TitleComponent } from "../TitleComponent";
import { translate } from "../../translations/localization";


const QuestionsComponent = ({ clients, bullets }) => {
  const [selected, setSelected] = useState(translate("register"));
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;

  const renderMPS = () => {
    return (
    <div className="list-wrapper" style={{ width: "85%", marginLeft: "2.2%" }}>
        <div className="point-and-text" style={{marginTop:"10%"}}>
                  <div className={`squaree`}/>
                  <h6 className={`tabb-title regular`} style={{fontSize:isMobileVersion === 1 ? "16px" :"18px", marginBottom: "5%"}}>{translate("payBullet-1")}</h6>
        </div>
        <div className="point-and-text" style={{marginTop:"5%" }}>
                  <div className={`squaree`}/>
                  <h6 className={`tabb-title regular`} style={{fontSize:isMobileVersion === 1 ? "16px" :"18px", marginBottom: "5%"}}>{translate("payBullet-2")}</h6>
        </div>
        <div className="point-and-text" style={{marginTop:"5%"}}>
                  <div className={`squaree`} />
                  <h6 className={`tabb-title regular`} style={{fontSize:isMobileVersion === 1 ? "16px" :"18px", marginBottom: "5%"}}>{translate("payBullet-3")}</h6>
        </div>
        <div className="point-and-text" style={{marginTop:"5%"}}>
                  <div className={`squaree`} />
                  <h6 className={`tabb-title regular`} style={{fontSize:isMobileVersion === 1 ? "16px" :"18px", marginBottom: "5%"}}>{translate("payBullet-4")}</h6>
        </div>
    </div>)
  }

  const Options = {
    First: [translate("register"), translate("registerAns")],
    Credits: [translate("cost"), translate("costAns")],
    Graphic: [translate("car"), translate("carAns")],
    Management: [translate("time"), translate("timeAns")],
    Instructors: [translate("charged"), translate("chargedAns")],
    MPS: [translate("pay"), translate("payAns")],
    // MPS: [translate("pay"), translate("payAns")],
  };

  return (
    <>
      {isMobileVersion === 1 ? (
        <>
          <div className={`mobile-full-container ${clients && "clients"}`}>
            <TitleComponent title={translate("faq")} fullLine mobile />
            {Object.values(Options).map((option, index) => {
              return (
                <>
                  {/* <div className="mobile-inner-header" id={index} key={index} style={{ height: index === 2 ? "4%" : index === 4 ? "4%" : "3.5%" }}> */}
                  <div className={`mobile-inner-header ${index === 0 ? 'small-margin' : ''}`} id={index} key={index}>
                    <p
                      style={{
                        marginLeft: "2%",
                        fontSize: "15px",
                        marginTop: clients && "2%"
                      }}
                    >
                      <b>{option[0]}</b>
                    </p>
                  </div>
                  {(bullets && option[0] === translate("pay")) ?
                  <>
                  <p style={{ width: "85%", marginLeft: "5%", fontSize: "15px", marginBottom: index === 5 && "8%" }}>
                    {option[1]}
                  </p>
                  {renderMPS()}
                  </> :
                  <p
                    style={{ width: "85%", marginLeft: "5%", fontSize: "15px", marginBottom: index === 5 && "8%" }}
                  >
                    {option[1]}
                  </p>}
                </>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <div className="Full-container">
            <div className="Left-container">
              <Tabs
                howitworks
                black
                selected={selected}
                tabs={[
                  Options.First[0],
                  Options.Credits[0],
                  Options.Graphic[0],
                  Options.Management[0],
                  Options.Instructors[0],
                  Options.MPS[0],
                ]}
                onSelect={(tab) => setSelected(tab)}
              />
            </div>
            <div className="Right-container">
              <div className="Questions">
                <div className="question-and-icon">
                  <TitleComponent
                    title={translate("faq")}
                    leftfullwidth
                    className="question"
                    id="question"
                  />
                </div>
                <div className={`answer`} id="answer1">
                  <>
                  {Object.values(Options).map(option => {
                      if (option.includes(selected) && selected !== translate("pay")) return <p style={{ width: "85%", marginLeft: "3%" }}>{option[1]}</p>
                      else if (bullets && option.includes(selected) && selected === translate("pay")) {
                        return (
                          <>
                          <p style={{ width: "85%", marginLeft: "3%" }}>{option[1]}</p>
                          {renderMPS()}
                          </>
                        )
                      }
                      else return null
                    })}
                  </>

                   {/* <p style={{ width: "85%", marginLeft: "3%" }}>
                     {Object.values(Options).map(option => {
                       if (option.includes(selected)) return option[1]
                       else return null
                     })}
                   </p> */}
              
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default QuestionsComponent;

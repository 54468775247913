import { combineReducers } from 'redux'
// import { createNavigationReducer } from 'react-navigation-redux-helpers'
import { routerReducer } from 'react-router-redux'

import starter from './starter'
import articles from './articles'
import map from './map'

export default combineReducers({
  starter,
  routerReducer,
  articles,
  map
})

import React,{useState,useEffect} from "react";
import {DownloadSection, QuestionsComponent, Section, TitleComponent} from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";




const App = () => {

    const [selected, setSelected] = useState(0);
    const [secondSelected,setSecondSelected] = useState(0);
    const [thirdSelected,setThirdSelected] = useState(0);
    const isMobileVersion = window.innerWidth < 900 ? 1 : 0;
    const screenSize = window.innerWidth;

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }, []);


      const textMap = [translate("app2-6"),translate("app2-7"),translate("app2-8"),translate("app2-9"),translate("app2-10")];
      // const secondTextMap = [translate("app2-12"),translate("app2-13"),translate("app2-14"),translate("app2-15"),translate("app2-16")];
      const ThirdtextMap = [
        {
            title:translate("app4-3"),
            text:translate("app4-4")
        },
        {
            title:translate("app4-5"),
            text:translate("app4-6")
        },
    ]


    return(
      <div className="home-container">
        <Section 
        title={translate("app1-1")}
        image={`url(${require("../../assets/images/app-section-1.png")})`}
        // height = {isMobileVersion === 1 && 125}
        height = {screenSize < 1500 && screenSize > 900 ? 100 : 105}
        titleMargin={3}
        titleMobile={isMobileVersion === 1 && true}
        renderContent={()=>{
          return(
                <>
                <p className='text-info'>{translate("app1-2")}</p>
                {isMobileVersion === 1 ? <p style={{marginTop:"11%"}}>{translate("downloadFrom")}</p> :
                  <p style={{marginTop:"15%"}}>{translate("downloadFrom")}</p>
                }
                <div className={`buttons ${isMobileVersion === 1 && "mobile"}`}>
                    <div className={`button-app ${isMobileVersion === 1 && "mobile"}`} onClick={() => {window.open("https://apps.apple.com/app/wink-charging/id1615549467")}}/>
                    <div className={`button-google ${isMobileVersion === 1 && "mobile"}`} onClick={() => {window.open("https://play.google.com/store/apps/details?id=com.winkchargingapp&hl=bg&gl=US")}}/>
                </div>
                </>
            )
        }}
        />

        {isMobileVersion === 1 ? 
        <div className="white-container">
          <TitleComponent title={translate("app2-1")} fullLine mobile marginBottom={2} />
          <Section
          image = {`url(${require("../../assets/images/app-section-2-1.png")})`}
          title={""}
          height={95}
          maxHeight={35}
          renderContent={()=>{
            return(
              <div className="overlay-container">
                  <div className="wrapper" style={{marginLeft:"3%"}}>
                    <div className={`flex-container selected`} onClick={() => setSelected(0)}>
                      <div className="line-and-text">
                          <div className={`left-line selected`}/>
                          <h6 className={`tab-title selected regular`}>{translate("app2-2")}</h6>
                      </div>
                    </div>
                    <p className={`text-info selected`} style={{fontSize:"16px"}}>
                    {translate("app2-3")}
                    </p>
                  </div>
              </div>
          );
        }}
        />
        <Section
        image = {`url(${require("../../assets/images/app-section-2-2.png")})`}
        title={""}
        maxHeight={58}
        renderContent={()=>{
          return(
          <div className="overlay-container">
              <div className="wrapper" style={{marginLeft:"3%"}}>
                <div className={`flex-container selected`} onClick={() => setSelected(1)}>
                  <div className="line-and-text">
                      <div className={`left-line selected`}/>
                      <h6 className={`tab-title selected`}>{translate("app2-4")}</h6>
                  </div>
                </div>
                <p className={`text-info selected`} style={{fontSize:"16px"}}>{translate("app2-5")}</p>
                {textMap.map((element,index)=>{
                    return(<div className={`pointt-and-text selected`}>
                    <div className={`squaree`}/>
                    <h6 className={`tabb-title regular`} style={{fontSize:"16px",marginBottom:"2%"}}>{textMap[index]}</h6>
                </div>)
                })}
              </div>
          </div>
          );
        }}
        />
        <Section
        image = {`url(${require("../../assets/images/app-section-2-3.png")})`}
        title={""}
        height={95}
        maxHeight={30}
        renderContent={()=>{
          return(
          <div className="overlay-container">
              <div className="wrapper" style={{marginLeft:"3%"}}>
                <div className={`flex-container selected`} onClick={() => setSelected(2)}>
                  <div className="line-and-text">
                      <div className={`left-line selected`}/>
                      <h6 className={`tab-title selected`}>{translate("app2-12")}</h6>
                  </div>
                </div>
                <p className={`text-info selected`} style={{fontSize:"16px"}}>{translate("app2-13")}</p>
              </div>
          </div>
          );
        }}
        />
        <Section
        image = {`url(${require("../../assets/images/app-section-2-4.png")})`}
        title={""}
        height={95}
        maxHeight={30}
        renderContent={()=>{
          return(
          <div className="overlay-container">
              <div className="wrapper" style={{marginLeft:"3%"}}>
                <div className={`flex-container selected`} onClick={() => setSelected(3)}>
                  <div className="line-and-text">
                      <div className={`left-line selected`}/>
                      <h6 className={`tab-title selected`}>{translate("filters")}</h6>
                  </div>
                </div>
                <p className={`text-info selected`} style={{fontSize:"16px"}}>{translate("app2-11")}</p>
              </div>
          </div>
          );
        }}
        />
        </div>
        :
        <Section
        image = {selected === 0 ? `url(${require("../../assets/images/app-section-2-1.png")})` : selected === 1 ? `url(${require("../../assets/images/app-section-2-2.png")})` : selected === 2 ? `url(${require("../../assets/images/app-section-2-3.png")})` : selected === 3 ? `url(${require("../../assets/images/app-section-2-4.png")})` : ""}
        title={translate("app2-1")}
        invert
        fullLine
        height={115}
        right={isMobileVersion === 0 && true}
        renderContent={()=>{
          return(
          <div className="overlay-container">
            <div className="wrapper detailed">
                <div className={`flex-container ${selected === 0 && "selected"}`} onClick={() => setSelected(0)}>
                  <div className="line-and-text">
                      <div className={`left-line ${selected === 0 && "selected"}`}/>
                      <h6 className={`tab-title no-margin ${selected === 0 && "selected"}`}>{translate("app2-2")}</h6>
                  </div>
                </div>
                <p className={`text-info ${selected === 0 && "selected"}`}>
                {translate("app2-3")}
                </p>
              </div>
              <div className="wrapper detailed">
                <div className={`flex-container ${selected === 1 && "selected"}`} onClick={() => setSelected(1)}>
                  <div className="line-and-text">
                      <div className={`left-line ${selected === 1 && "selected"}`}/>
                      <h6 className={`tab-title no-margin ${selected === 1 && "selected"}`}>{translate("app2-4")}</h6>
                  </div>
                </div>
                <p className={`text-info ${selected === 1 && "selected"}`}>{translate("app2-5")}</p>
                {textMap.map((element,index)=>{
                    return(<div className={`pointt-and-text ${selected === 1 && "selected"}`}>
                    <div className={`squaree`}/>
                    <h6 className={`tabb-title no-margin notbold`}>{textMap[index]}</h6>
                </div>)
                })}
              </div>
              <div className="wrapper detailed">
                <div className={`flex-container ${selected === 2 && "selected"}`} onClick={() => setSelected(2)}>
                  <div className="line-and-text">
                      <div className={`left-line ${selected === 2 && "selected"}`}/>
                      <h6 className={`tab-title no-margin ${selected === 2 && "selected"}`}>{translate("app2-12")}</h6>
                  </div>
                </div>
                <p className={`text-info ${selected === 2 && "selected"}`}>{translate("app2-13")}</p>
              </div>
              <div className="wrapper detailed">
                <div className={`flex-container ${selected === 3 && "selected"}`} onClick={() => setSelected(3)}>
                  <div className="line-and-text">
                      <div className={`left-line ${selected === 3 && "selected"}`}/>
                      <h6 className={`tab-title no-margin ${selected === 3 && "selected"}`}>{translate("filters")}</h6>
                  </div>
                </div>
                <p className={`text-info ${selected === 3 && "selected"}`}>{translate("app2-11")}</p>
              </div>
          </div>
          );
        }}
        />
      }

      {isMobileVersion === 1 ?
      <>
      <TitleComponent title={translate("app3-1")}  fullLine mobile />
      <Section
          title={""}
          image={`url(${require("../../assets/images/app-section-3-1.png")})`}
          height={100}
          maxHeight={38}
          renderContent={() => {
            return(
              <>
              <h4 style={{color:"#00D688",fontSize:"16px",marginLeft:"5%"}}>{translate("app3-2")}</h4>
              <p className='text-info'>{translate("app3-3")}</p> 
              </>)
            }}
        />
        <Section
          title={""}
          image={`url(${require("../../assets/images/app-section-3-2.png")})`}
          height={100}
          maxHeight={38}
          renderContent={() => {
            return(
              <>
              <h4 style={{color:"#00D688",fontSize:"16px",marginLeft:"5%"}}>{translate("app3-4")} </h4>
              <p className='text-info'>{translate("app3-5")} </p>
              </>)
            }}
        />
        <Section
          title={""}
          image={`url(${require("../../assets/images/app-section-3-3.png")})`}
          height={100}
          maxHeight={38}
          renderContent={() => {
            return(
              <>
              <h4 style={{color:"#00D688",fontSize:"16px",marginLeft:"5%"}}>{translate("app3-6")} </h4>
              <p className='text-info'>{translate("app3-7")} </p>
              </>)
            }}
        />
      </> 
      : <Section
          fullwidth
          title={translate("app3-1")} 
          image={secondSelected === 0 ? `url(${require("../../assets/images/app-section-3-1.png")})` : secondSelected === 1 ? `url(${require("../../assets/images/app-section-3-2.png")})` : secondSelected === 2 ? `url(${require("../../assets/images/app-section-3-3.png")})` : ""}
          moveupinner
          height={105}
          renderContent={() => {
            return(
              <>
              <div className="upperr-arrow-container">
                <div className={`inner-arrow ${secondSelected === 0 ? "sel" : ""}`}/>
                <div className={`inner-arrow-1 ${secondSelected === 1 ? "sel" : ""}`}/>
                <div className={`inner-arrow-2 ${secondSelected === 2 ? "sel" : ""}`}/>
              </div>
              <div className="innerr-contact-tabs-container">
                <div className="first-element" onClick={() => {setSecondSelected(0)}}>
                  <div className={`inner-element ${secondSelected === 0 ? "selected" : ""}`}>{translate("app3-2")} </div>
                </div>
                <div className="second-element" onClick={() => {
                    setSecondSelected(1)
                }}>
                <div className={`inner-element ${secondSelected === 1 ? "selected" : ""}`}>{translate("app3-4")} </div>
                </div>
                <div className="third-element" onClick={() => {
                    setSecondSelected(2)
                  }}>
                  <div className={`inner-element ${secondSelected === 2 ? "selected" : ""}`}>{translate("app3-6")} </div>
                  </div>
              </div>
              {secondSelected === 0 ? <p className="margin-top">{translate("app3-3")} </p> 
              : secondSelected === 1 ? <p className="margin-top">{translate("app3-5")} </p> 
              : secondSelected === 2 ? <p className="margin-top">{translate("app3-7")} </p> : ""}
                </>)
            }}
        />
      }

      {isMobileVersion === 1 ?
      <>
      <TitleComponent title={translate("app2-12")} fullLine mobile />
      <Section
          title={""}
          image={`url(${require("../../assets/images/app-section-7-0.png")})`}
          height={105}
          maxHeight={120}
          maxHeightImage={120}
          renderContent={() => {
            return(
              <>
              <h4 style={{color:"#00D688",fontSize:"16px",marginLeft:"5%"}}>{translate("app3-2")}</h4>
              <p className='text-info'>{translate("app7-1")}</p> 
              </>)
            }}
        />
        <Section
          title={""}
          image={`url(${require("../../assets/images/app-section-7-1.png")})`}
          height={100}
          maxHeight={38}
          renderContent={() => {
            return(
              <>
              <h4 style={{color:"#00D688",fontSize:"16px",marginLeft:"5%"}}>{translate("app3-4")} </h4>
              <p className='text-info'>{translate("app7-2")} </p>
              </>)
            }}
        />
        <Section
          title={""}
          image={`url(${require("../../assets/images/app-section-7-2.png")})`}
          height={100}
          maxHeight={38}
          renderContent={() => {
            return(
              <>
              <h4 style={{color:"#00D688",fontSize:"16px",marginLeft:"5%"}}>{translate("app3-6")} </h4>
              <p className='text-info'>{translate("app7-3")} </p>
              </>)
            }}
        />
        <Section
          title={""}
          image={`url(${require("../../assets/images/app-section-7-3.png")})`}
          height={50}
          maxHeight={50}
          noContent={true}
        />
      </> 
      : <Section
          fullwidth
          title={translate("app2-12")} 
          image={thirdSelected === 0 ? `url(${require("../../assets/images/app-section-7-1.png")})` : thirdSelected === 1 ? `url(${require("../../assets/images/app-section-7-2.png")})` : thirdSelected === 2 ? `url(${require("../../assets/images/app-section-7-3.png")})` : ""}
          moveupinner
          right={true}
          height={105}
          renderContent={() => {
            return(
              <>
              <div className="upperr-arrow-container">
                <div className={`inner-arrow ${thirdSelected === 0 ? "sel" : ""}`}/>
                <div className={`inner-arrow-1 ${thirdSelected === 1 ? "sel" : ""}`}/>
                <div className={`inner-arrow-2 ${thirdSelected === 2 ? "sel" : ""}`}/>
              </div>
              <div className="innerr-contact-tabs-container">
                <div className="first-element" onClick={() => {setThirdSelected(0)}}>
                  <div className={`inner-element ${thirdSelected === 0 ? "selected" : ""}`}>{translate("app3-2")} </div>
                </div>
                <div className="second-element" onClick={() => {
                    setThirdSelected(1)
                }}>
                <div className={`inner-element ${thirdSelected === 1 ? "selected" : ""}`}>{translate("app3-4")} </div>
                </div>
                <div className="third-element" onClick={() => {
                    setThirdSelected(2)
                  }}>
                  <div className={`inner-element ${thirdSelected === 2 ? "selected" : ""}`}>{translate("app3-6")} </div>
                  </div>
              </div>
              {thirdSelected === 0 ? <p className="margin-top">{translate("app7-1")} </p> 
              : thirdSelected === 1 ? <p className="margin-top">{translate("app7-2")} </p> 
              : thirdSelected === 2 ? <p className="margin-top">{translate("app7-3")} </p> : ""}
                </>)
            }}
        />
      }

    <DownloadSection/>

      {isMobileVersion === 1 ? 
  
      <Section
      mobile={isMobileVersion}
      title={""}
      height={125}
      backgroundWhite
      image={`url(${require("../../assets/images/app-section-5.png")})`}
      titleMobile
      renderContent={()=>{
        return(
              <>
              <TitleComponent title={translate("app4-1")} fullLine mobile/>
              <p className={`text-info selected`} style={{fontSize:"16px"}}>{translate("app4-2")}</p>
              <div className="inner-charging-wrapper">
              <div className={`flexx-container`}>
                <div className="point-and-text">
                    <div className={`squaree`}/>
                    <h6 className={`tabb-title regular`}>{translate("app4-3")}</h6>
                </div>
              </div>
            </div>
            <div className="inner-wrapper">
              <div className={`flexx-container`}>
                <div className="point-and-text">
                    <div className={`squaree`}/>
                    <h6 className={`tabb-title regular`}>{translate("app4-4")}</h6>
                </div>
              </div>
            </div>
            <div className="inner-wrapper">
              <div className={`flexx-container`}>
                <div className="point-and-text">
                    <div className={`squaree`}/>
                    <h6 className={`tabb-title regular`}>{translate("app4-5")}</h6>
                </div>
              </div>
            </div>
            <div className="inner-wrapper">
              <div className={`flexx-container`}>
                <div className="point-and-text">
                    <div className={`squaree`}/>
                    <h6 className={`tabb-title regular`}>{translate("app4-6")}</h6>
                </div>
              </div>
            </div>
              </>
          )
      }}
      />
  
      :<Section
        title={translate("app4-1")}
        image={`url(${require("../../assets/images/app-section-5.png")})`}
        invert
        fullLine
        right={isMobileVersion === 0 && true}
        renderContent={()=>{
          return(
                <>
                <p style={{fontSize:"22px"}}>{translate("app4-2")}</p>
                <div className="inner-charging-wrapper" style={{marginTop:"0%"}}>
                <div className={`flexx-container`}>
                  <div className="point-and-text">
                      <div className={`squaree`}/>
                      {/* <h6 className={`tabb-title`} style={{fontSize:"18px"}}><b>{ThirdtextMap[0].title}</b></h6> */}
                      <h6 className={`tabb-title regular`} style={{fontSize:"18px"}}>{translate("app4-3")}</h6>
                  </div>
                </div>
                {/* <p className={`textt-info`} style={{fontSize:"18px"}}>
                  {ThirdtextMap[0].text}
                </p> */}
              </div>
              <div className="inner-wrapper">
                <div className={`flexx-container`}>
                  <div className="point-and-text">
                      <div className={`squaree`}/>
                      {/* <h6 className={`tabb-title`} style={{fontSize:"18px"}}><b>{ThirdtextMap[1].title}</b></h6> */}
                      <h6 className={`tabb-title regular`} style={{fontSize:"18px"}}>{translate("app4-4")}</h6>
                  </div>
                </div>
                {/* <p className={`textt-info`} style={{fontSize:"18px"}}>
                  {ThirdtextMap[1].text}
                </p> */}
              </div>
              <div className="inner-wrapper">
                <div className={`flexx-container`}>
                  <div className="point-and-text">
                      <div className={`squaree`}/>
                      {/* <h6 className={`tabb-title`} style={{fontSize:"18px"}}><b>{ThirdtextMap[1].title}</b></h6> */}
                      <h6 className={`tabb-title regular`} style={{fontSize:"18px"}}>{translate("app4-5")}</h6>
                  </div>
                </div>
                {/* <p className={`textt-info`} style={{fontSize:"18px"}}>
                  {ThirdtextMap[1].text}
                </p> */}
              </div>
              <div className="inner-wrapper">
                <div className={`flexx-container`}>
                  <div className="point-and-text">
                      <div className={`squaree`}/>
                      {/* <h6 className={`tabb-title`} style={{fontSize:"18px"}}><b>{ThirdtextMap[1].title}</b></h6> */}
                      <h6 className={`tabb-title regular`} style={{fontSize:"18px"}}>{translate("app4-6")}</h6>
                  </div>
                </div>
                {/* <p className={`textt-info`} style={{fontSize:"18px"}}>
                  {ThirdtextMap[1].text}
                </p> */}
              </div>
                </>
            )
        }}
        />
      }
        <Section
        title={translate("app5-1")}
        fullLine = {isMobileVersion === 1 ? true : false}
        style={isMobileVersion === 1 ?{marginLeft:"5%",marginTop:"5%"} : ""}
        fullwidth
        image={`url(${require("../../assets/images/app-section-6.png")})`}
        height = {isMobileVersion === 1 ? 111 : 100}
        titleMobile={isMobileVersion === 1 && true}
        renderContent={()=>{
          return(
              <>
                <p style={{fontSize:isMobileVersion === 1 ? "16px" :"18px", marginBottom: "22px"}}>{translate("app5-2")}</p>
                <div className="point-and-text"  style={{marginTop:"5%"}}>
                          <div className={`squaree`}/>
                          <h6 className={`tabb-title regular`} style={{fontSize:isMobileVersion === 1 ? "16px" :"18px"}}>{translate("app5-3")}</h6>
                </div>
                <div className="point-and-text" >
                          <div className={`squaree`}/>
                          <h6 className={`tabb-title regular`} style={{fontSize:isMobileVersion === 1 ? "16px" :"18px"}}>{translate("app5-4")}</h6>
                </div>
                <div className="point-and-text">
                          <div className={`squaree`} />
                          <h6 className={`tabb-title regular`} style={{fontSize:isMobileVersion === 1 ? "16px" :"18px"}}>{translate("app5-5")}</h6>
                </div>
                <div className="point-and-text">
                          <div className={`squaree`} />
                          <h6 className={`tabb-title regular`} style={{fontSize:isMobileVersion === 1 ? "16px" :"18px"}}>{translate("app5-6")}</h6>
                </div>
                <div className="point-and-text">
                          <div className={`squaree`} />
                          <h6 className={`tabb-title regular`} style={{fontSize:isMobileVersion === 1 ? "16px" :"18px"}}>{translate("app5-7")}</h6>
                </div>
              </>
            )
        }}
        />

        <Section
        title={translate("app6-1")}
        image={`url(${require("../../assets/images/app-section-7.png")})`}
        height={100}
        backgroundWhite = {isMobileVersion === 1 ? true : false}
        invert = {isMobileVersion === 1 ? false : true}
        titleMargin={-15}
        fullLine = {isMobileVersion === 1 ? true : false}
        fullwidth
        titleMobile={isMobileVersion === 1 && true}
        right={isMobileVersion === 0 && true}
        renderContent={()=>{
          return(
              <>
                <p className={`${isMobileVersion === 1 ? 'text-info selected' : '' } `} style={{width:"90%"}}>{translate("app6-2")}</p>
              </>
            )
        }}
        />
        <QuestionsComponent bullets />
        </div>
    )
}

export default App;
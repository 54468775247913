import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyAAP_e-9URTUBDjBNogHAgF9RMdnJxH0xg',
  authDomain: 'wink-charging.firebaseapp.com',
  projectId: 'wink-charging',
  storageBucket: 'wink-charging.appspot.com',
  messagingSenderId: '56117478581',
  appId: '1:56117478581:web:0911504c58ee5301ca2afc',
  measurementId: 'G-WMWDQHX1CV'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// const analytics = getAnalytics(app)
getAnalytics(app)

import React from "react";
import { translate } from "../../translations/localization";
import "./styles.scss";

const DownloadSection = () =>{
    const isMobileVersion = window.innerWidth < 900 ? 1 : 0;

    return(
        <div className = {`download-section ${isMobileVersion === 1 ? "mobile" : ""}`}>
            <div className="download-inner">
                <div className="inner-logo"/>
                {isMobileVersion === 1 ?  <h1 style={{marginTop:"8%",fontSize:"16px"}}><b>{translate("download")}</b></h1> 
                :  <h1 style={{marginTop:"5%",fontSize:"38px"}}><b>{translate("download")}</b></h1>}
                <div className="download-now">
                    <div className="app-store" onClick={() => {window.open("https://apps.apple.com/app/wink-charging/id1615549467")}}/>
                    <div className="google-play" onClick={() => {window.open("https://play.google.com/store/apps/details?id=com.winkchargingapp&hl=bg&gl=US")}}/>
                </div>
            </div>
        </div>
)}

export default DownloadSection;
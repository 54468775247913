import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { ajax } from "rxjs/ajax";
import { MapTypes } from "../actions";
import { API } from "../config/settings";
import { basicAuth } from "../config/settings";

export const getLocationsEpic = (action$) => action$.pipe(
    ofType(MapTypes.GET_LOCATIONS),
    switchMap(({ payload, onSuccess }) => ajax({
        url: `${API}location/web/list`,
        method: "POST",
        headers: basicAuth,
        body: JSON.stringify(payload),
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            obs.next({ type: MapTypes.GET_LOCATIONS_SUCCESS, payload: { ...response.payload } })
            if (onSuccess) onSuccess(response)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.log(err)
            obs.complete()
        }))
    ))
)

export const getSelectedLocationEpic = (action$) => action$.pipe(
    ofType(MapTypes.GET_SELECTED_LOCATION),
    switchMap(({ payload, onSuccess }) => ajax({
        url: `${API}location/web/${payload?._id}`,
        method: "GET",
        headers: basicAuth,
        body: JSON.stringify(payload),
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            obs.next({ type: MapTypes.GET_SELECTED_LOCATION_SUCCESS, payload: { ...response.payload } })
            if (onSuccess) onSuccess(response)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.log(err)
            obs.complete()
        }))
    ))
)
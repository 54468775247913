import React,{useState,useEffect} from "react";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import "./styles.scss";
import { setLocale, translate } from "../../translations/localization";

const Header = () => {
  const location = useLocation();


  const [language, setLanguage] = useState("");
  const languageCode = localStorage.getItem("immoLanguage");

  useEffect(() => {
    if (!languageCode) {
      localStorage.setItem("immoLanguage", "bg");
      setLanguage("bg");
      setLocale("bg");
    } else {
      setLanguage(languageCode);
    }
  }, [languageCode]);

  return (
  <div className="header-container">
        <div className="left-header-container">
          <div className={`left-header-inner-element ${location.pathname === "/charging" && "selected"}`} onClick={()=>{history.push("/charging")}}> {translate("header1")} </div>
          <div className={`left-header-inner-element ${location.pathname === "/app" && "selected"}`} onClick={()=>{history.push("/app")}}> {translate("header2")} </div>
        </div>

        <div className="middle-header-container">
          <div className="header-logo" onClick={()=>{history.push("/")}}/>
        </div>

        <div className="right-header-container">
          <div style={{display:"flex",width:"80%",height:"20%",marginLeft:"-15%"}}>
            <div className={`right-header-inner-element ${location.pathname === "/clients" && "selected"}`} onClick={()=>{history.push("/clients")}}> {translate("header3")} </div>
            <div className={`right-header-inner-element ${location.pathname === "/about" && "selected"}`} onClick={()=>{history.push("/about")}}> {translate("header4")} </div>
          </div>
         
          <div className="languages-container">
            <div
                className={`language border ${language === "bg" && "selected"}`}
                onClick={() => {
                  setLanguage("bg");
                  setLocale("bg");
                  localStorage.setItem("immoLanguage", "bg");
                  history.push(location.pathname + location.search)
                }}
              >
            BG
            </div>
            <div className="separator"/>
            <div
              className={`language ${language === "en" && "selected"}`}
              onClick={() => {
                setLanguage("en");
                setLocale("en");
                localStorage.setItem("immoLanguage", "en");
                history.push(location.pathname + location.search)
              }}
            >
              EN
           </div>
          </div>
        </div>

  </div>
  );
};

export default Header;

import React, { useState } from 'react'
import "./styles.scss";
import { GoogleMap, useJsApiLoader,Marker,InfoWindow } from '@react-google-maps/api';
import pin from "../../assets/icons/MapMarker.svg";
import pinStation from "../../assets/icons/MapStationMarker.svg";
import Geocode from "react-geocode";

const containerStyle = {
  width: '50%',
  height: '100%'
};

const MobilecontainerStyle = {
width:'100%',
height:'50vh'
};

const styles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#1D2C4D"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8EC3B9"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1A3646"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#4B6878"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#64779E"
      }
    ]
  },
  {
    "featureType": "administrative.province",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#4B6878"
      }
    ]
  },
  {
    "featureType": "landscape.man_made",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#334E87"
      }
    ]
  },
  {
    "featureType": "landscape.natural",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#023E58"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#283D6A"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#6F9BA5"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1D2C4D"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#023E58"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#3C7680"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#304A7D"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#98A5BE"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1D2C4D"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#2C6675"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#255763"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#B0D5CE"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#023E58"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#98A5BE"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1D2C4D"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#283D6A"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#3A4762"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#0E1626"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#4E6D70"
      }
    ]
  }
]




function Mapforgoogle({mobile,X,Y, locations, locationData, setSelectedLocation}) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyALipb3WN32r90_zyf2wk-vNrEod_JdR5Q"
  })
  Geocode.setApiKey("AIzaSyALipb3WN32r90_zyf2wk-vNrEod_JdR5Q");

  const [position, setPosition] = React.useState({
     
        lat: X,
        lng: Y
    });
  const [isOpen,setIsOpen] =useState(false);
  const [markerAddress,setMarkerAddress] = useState("");
    
  const mapRef = React.useRef(null);

  // function renderLocations() {
  //   if (!locations || !locations?.length) return

  //   // setPosition({ lat: 42.698040, lng: 23.325219 })

  //   locations.map((locationData, index) => {
  //     let X = locationData[0]
  //     let Y = locationData[1]

  //     console.log(position)
  //     console.log(X)
  //     console.log(Y)

  //     return (
  //       <Marker
  //       key={X + Y}
  //       icon={ { url:pin} }
  //       position={{lat: X, lng: Y}}
  //       onClick={() => {
  //         setPosition({ lat: X, lng: Y })
  //         handleToggle()
  //       }}
  //     >
  //     {isOpen &&
  //       <InfoWindow
  //           onCloseClick={handleToggle}
  //           >
  //         <span style={{color:"black"}}>{markerAddress}</span>
  //       </InfoWindow>
  //     }
  //     </Marker>
  //     )
  //   })
  // }

  function handleLoad(map) {
    mapRef.current = map;
  }

  // function handleLoadLocations(map) {
  //   mapRef.current = map;
  //   const bounds = new window.google.maps.LatLngBounds();
  //   let coordinates = locations.map(location => location = { lat: location[0], lng: location[1] } )
  //   coordinates.forEach((position) => bounds.extend(position));
  //   map.fitBounds(bounds);
  // }
  
  function handleCenter() {
    if (!mapRef.current) return;

    const newPos = mapRef.current.getCenter().toJSON();
    setPosition(newPos);
  }


  function handleToggle (){
    if(isOpen === true) setIsOpen(false);
    else {
      Geocode.fromLatLng(position.lat, position.lng).then(
        response => {
          const address = response.results[0].formatted_address;
          setMarkerAddress(address);
        },
        error => {
          console.error(error);
        }
      );
      setIsOpen(true);
    }
  }

  return isLoaded ? (
    
      <GoogleMap
        mapContainerStyle={mobile === 1 ? MobilecontainerStyle : containerStyle}
        zoom={mobile === 1 ? 12 : 13}
        options={{styles: styles}}
        onLoad={handleLoad}
        // onLoad={handleLoadLocations}
        onDragEnd={handleCenter}
        center={position}
        id="map"
      >
          {!locations?.length && 
          <Marker
            icon={ { url:pin} }
            position={{lat: X, lng: Y}}
            onClick={handleToggle}
          >
          {isOpen &&
            <InfoWindow
                onCloseClick={handleToggle}
                >
              <span style={{color:"black"}}>{markerAddress}</span>
            </InfoWindow>
          }
          </Marker>}


          {locations?.map((singleLocation, index) => {
            let XCustom = singleLocation[1]
            let YCustom = singleLocation[0]
            
            return  (
            <Marker 
            key={index + XCustom} 
            icon={ { url:pinStation} } 
            position={{ lat: XCustom, lng: YCustom }}
            onClick={() => {
            setPosition({ lat: XCustom, lng: YCustom })
            handleToggle()
            setSelectedLocation(locationData[index])
            }}>
            </Marker>)
            })}

      </GoogleMap>
  ) : <></>
}

export default React.memo(Mapforgoogle);
import React from "react";
import "./styles.scss";

function Tabs({ tabs, selected, onSelect, wide, superwide,howitworks }) {
  return (
    <div className={`tabs-container ${wide && "wide"} ${superwide && "superwide"} ${howitworks && "howitworks"}`}>
      {tabs.map((tab) => {
        return (
          <div
            className={`tab-element ${selected === tab && "selected"} `}
            onClick={() => onSelect(tab)}
          >
            <span style={{marginLeft:"3%",whiteSpace:"pre-wrap",textAlign:"left"}}>{tab}</span>
            {selected === tab ? <div className="arrow"/> : ""}
          </div>
        );
      })}
    </div>
  );
}

export default Tabs;

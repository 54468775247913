import { MapTypes } from '../actions'

const initialState = {
    data: {},
    locations: [],
    coordinates: [],
    selectedLocation: {}
}

const getCoordinates = (payload) => {
    let coordinates = []
    payload.forEach((data, index) => {
      if (data?.location?.coordinates) coordinates.push(data.location.coordinates)
    })

    return coordinates
}

const articles = (state = initialState, { type, payload }) => {
    switch (type) {
        case MapTypes.GET_LOCATIONS_SUCCESS:
            return { ...state, coordinates: getCoordinates(payload.docs), data: payload.docs }
        case MapTypes.GET_SELECTED_LOCATION_SUCCESS:
            return { ...state, selectedLocation: payload }
            // if (payload.docs && payload.page && payload.page > 1) return ({ ...state, ...payload, docs: [...(state?.docs || {}), ...payload.docs] })
            // else return ({ ...state, ...payload })
        default:
            return state
    }
}

export default articles

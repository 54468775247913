import { combineEpics } from 'redux-observable'
import * as starter from './starter'
import * as contact from './contact'
import * as articles from './articles'
import * as map from './map'

export const rootEpic = combineEpics(
  starter.loadingEpic,
  contact.sendEmail,
  articles.getArticles,
  articles.getArticle,
  map.getLocationsEpic,
  map.getSelectedLocationEpic,
)

import React, { useState,useEffect } from "react";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import "./styles.scss";
// import { Icon } from "react-icons-kit";
// import {menu} from 'react-icons-kit/entypo/menu'
// import { close } from "react-icons-kit/fa/close";
import { setLocale, translate } from "../../translations/localization";

const MobileHeader = () => {
  const location = useLocation();

  const [language, setLanguage] = useState("");
  const languageCode = localStorage.getItem("immoLanguage");

  useEffect(() => {
    if (!languageCode) {
      localStorage.setItem("immoLanguage", "bg");
      setLanguage("bg");
      setLocale("bg");
    } else {
      setLanguage(languageCode);
    }
  }, [languageCode]);


  const [open, setOpen] = useState(false);

  return (
    <div className="mobile-container">
      <div
        className="button"
        onClick={() => {
          setOpen(true);
          document.body.style.overflow = "hidden";
        }}
      >
        <div className="menu"/>
      </div>
      <div className="mobile-header-logo" onClick={() => history.push("/")} />
      {open && (
        <div className="opened-header">
          <div className="upper-opened-header">
            <div
              className="close-opened-header"
              onClick={() => {
                setOpen(false);
                document.body.style.overflow = "visible";
              }}
            >
              <div className="close"/>
            </div>
            <div
              className="mobile-header-element"
              onClick={() => {
                setOpen(false);
                document.body.style.overflow = "visible";
                history.push("/");
              }}
            >
              <div
                className={`mobile-header-inner-element ${
                  location.pathname === "/" && "selected"
                }`}
              >
                {translate("home")}
              </div>
            </div>
            <div
              className="mobile-header-element"
              onClick={() => {
                setOpen(false);
                document.body.style.overflow = "visible";
                history.push("/charging");
              }}
            >
              <div
                className={`mobile-header-inner-element ${
                  location.pathname === "/charging" && "selected"
                }`}
              >
                {translate("header1")}
              </div>
            </div>
            <div
              className="mobile-header-element"
              onClick={() => {
                setOpen(false);
                document.body.style.overflow = "visible";
                history.push("/app");
              }}
            >
              <div
                className={`mobile-header-inner-element ${
                  location.pathname === "/app" && "selected"
                }`}
              >
                {translate("header2")}
              </div>
            </div>
            <div
              className="mobile-header-element"
              onClick={() => {
                setOpen(false);
                document.body.style.overflow = "visible";
                history.push("/clients");
              }}
            >
              <div
                className={`mobile-header-inner-element ${
                  location.pathname === "/clients" && "selected"
                }`}
              >
                {translate("header3")}
              </div>
            </div>
            <div
              className="mobile-header-element"
              onClick={() => {
                setOpen(false);
                document.body.style.overflow = "visible";
                history.push("/about");
              }}
            >
              <div
                className={`mobile-header-inner-element ${
                  location.pathname === "/about" ? "selected" : location.pathname === "/article" ? "selected" : ""
                }`}
              >
                {translate("header4")}
              </div>
            </div>
            <div className="mobile-language-container" style={{display:"flex",width:"80%",justifyContent:"center"}}>
                <div
                    className={`language border ${language === "bg" && "selected"}`}
                    style={{marginRight:"2%"}}
                    onClick={() => {
                      setLanguage("bg");
                      setLocale("bg");
                      localStorage.setItem("immoLanguage", "bg");
                      history.push(location.pathname + location.search)
                      setOpen(false);
                      document.body.style.overflow = "auto";
                    }}
                  >
                  BG
                </div>
                <div className="separator"/>
                <div
                  className={`language ${language === "en" && "selected"}`}
                  style={{marginLeft:"2%"}}
                  onClick={() => {
                    setLanguage("en");
                    setLocale("en");
                    localStorage.setItem("immoLanguage", "en");
                    history.push(location.pathname + location.search)
                    setOpen(false)
                    document.body.style.overflow = "auto";
                  }}
                >
                  EN
              </div>
            </div>
            <div className="mobile-flex-container-apps">
            <div
              className="app-store white"
              onClick={() => {window.open("https://apps.apple.com/app/wink-charging/id1615549467")}}
            />
            <div
              className="google-play white"
              onClick={() => {window.open("https://play.google.com/store/apps/details?id=com.winkchargingapp&hl=bg&gl=US")}}
            />
          </div>
          </div>
          <div className="lower-opened-header"></div>
        </div>
      )}
    </div>
  );
};

export default MobileHeader;
import { articlesTypes } from '../actions'

const initialState = {
    loading: false
}

const articles = (state = initialState, { type, payload }) => {
    switch (type) {
        case articlesTypes.SET_ARTICLES_FIELDS:
            if (payload.docs && payload.page && payload.page > 1) return ({ ...state, ...payload, docs: [...(state?.docs || {}), ...payload.docs] })
            else return ({ ...state, ...payload })
        default:
            return state
    }
}

export default articles

import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import "./styles.scss";
import { TitleComponent } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { getArticle } from "../../actions";

const Article = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const _id = useMemo(() => {
        const query = new URLSearchParams(location.search)
        return Array.from(query.keys()).reduce((a, key) => ({ ...a, [key]: query.get(key) }), {})?._id
    }, [location])
    const { article } = useSelector(({ articles }) => articles)
    useEffect(() => { dispatch(getArticle({ payload: _id })) }, [dispatch, _id])


    const isMobileVersion = window.innerWidth < 900 ? 1 : 0;
    useEffect(() => { window.scrollTo(0, 0) }, [])

    const [selected, setSelected] = useState(0);
    const allPhotos = useMemo(() => [article?.coverPhoto.address, ...(article?.photos.map(({ address }) => address) || [])], [article])

    if (isMobileVersion) return <div className="mobile-article-container">
        <div className="arrow-left mobile" onClick={() => history.push("/about")} />
        <h3>{article?.title}</h3>
        <div className="mobile-right-article-container" style={{ backgroundImage: `url(${allPhotos[selected]})` }} />
        <div className="slider">
            <div className={`buttonn backwards ${selected === 0 && "inactive"}`} onClick={() => setSelected(selected - 1)} />
            <div className="slider-counter"><b>{selected + 1}</b> /{allPhotos?.length}</div>
            <div className={`buttonn forwards ${selected === allPhotos?.length - 1 && "inactive"}`} onClick={() => setSelected(selected + 1)} />
        </div>
        <div className="mobile-neshto">
            <div className="text-container" dangerouslySetInnerHTML={{ __html: article?.text }} />
        </div>
    </div>

    return <div className="article-container">
        <div className="left-article-container">
            <div className="arrow-left" onClick={() => history.push("/about")} />
            <TitleComponent title={article?.title} leftfullwidth marginleft={3} />
            <div className="text-container" dangerouslySetInnerHTML={{ __html: article?.text }} />
        </div>
        <div className="right-article-container" style={{ backgroundImage: `url(${allPhotos[selected]})` }}>
            <div className="slider">
                <div className={`buttonn backwards ${selected === 0 && "inactive"}`} onClick={() => setSelected(selected - 1)} />
                <div className="slider-counter"><b>{selected + 1}</b> /{allPhotos?.length}</div>
                <div className={`buttonn forwards ${selected === allPhotos?.length - 1 && "inactive"}`} onClick={() => setSelected(selected + 1)} />
            </div>
        </div>
    </div>;
}

export default Article;
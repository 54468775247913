import React,{useEffect, useState} from "react";
import {DownloadSection, QuestionsComponent, Section, TitleComponent, SectionForMap, ContactComponent,} from "../../components";
import { translate } from "../../translations/localization";
import { useSelector, useDispatch } from "react-redux";
import "./styles.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLocations, getSelectedLocation } from "../../actions";


// const textMap = [
//   {
//     title: translate("home3-2"),
//     text: translate("home3-3"),
//   },
//   {
//     title: translate("home3-4"),
//     text: translate("home3-5"),
//   }
// ];



const Home = () =>{
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Please wait a few seconds.");
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selected, setSelected] = useState(0);
  const coordinates = useSelector(state => state.map.coordinates)
  const mapData = useSelector(state => state.map.data)
  const currentLocation = useSelector(state => state.map.selectedLocation)

  const getPositionsCount = (stations) => {
    if (!stations) return 

    let count = 0
    stations.map(({ _outlets }) => {
      if (_outlets?.length) {
       return count += _outlets.length
      }
    })

    return count
  }

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    dispatch(getLocations({ noPagination: true }))
  }, []);

  useEffect(() => {
    if (selectedLocation?._id) dispatch(getSelectedLocation({ _id: selectedLocation?._id }))
  }, [selectedLocation]);

  return(
    <div className="home-container">
      {isMobileVersion === 1 ? 
      <Section
      title={translate("home1-1")}
      image={`url(${require("../../assets/images/homepage-section-1.png")})`}
      mobile={isMobileVersion}
      height={100}
      titleMobile
      renderContent={()=>{
        return(
        <>
         <h2 style={{fontSize:"20px",marginLeft:"5%"}}>{translate("home1-2")}</h2>
          <div className="inner-content">
            <p>{translate("home1-3")}</p>
          </div>
        </>
        )
      }}
      /> 
      : <Section
      title={translate("home1-1")}
      image={`url(${require("../../assets/images/homepage-section-1.png")})`}
      reversed
      invert
      height={100}
      style={{marginTop:"5%"}}
      right={isMobileVersion === 0 && true}
      renderContent={()=>{
        return(
        <>
          <h2 style={{fontSize:"27px"}}>{translate("home1-2")}</h2>
          <div className="inner-content">
            <p>{translate("home1-3")}</p>
          </div>
        </>

          )
      }}
      />
    }

    {isMobileVersion === 1 ? 
      <><Section
      title={translate("home2-1")}
      image={`url(${require("../../assets/images/homepage-section-2.png")})`}
      mobile={isMobileVersion}
      height={100}
      titleMobile
      renderContent={()=>{
        return(<p>{translate("home2-2")}</p>)
      }}
      />
      <p></p>
      </>
      :<>
      <div className="second-section">
          <div className="full-image"/>
            <div className="text space">
                <TitleComponent title={translate("home2-1")}/>
                <p className="info">{translate("home2-2")}</p>
            </div>
        </div>
        <p></p>
        </>

    }

    {/* {isMobileVersion === 1 ? <></> :
    <SectionForMap

     />} */}


{/* test map */}
    {/* {isMobileVersion === 0 ? (
        selected === 0 ? (
          <Section
            title={translate("home3-1")}
            map={selected === 0 ? 1 : 0}
            X={42.6624857000924}
            Y={23.380830984583703}
            image={""}
            fullInner
            height={100}
            renderContent={() => {
              return (
                <>
                  <p style={{ fontSize: "18px" }}>
                    {translate("home3-2")}
                  </p>
                  <p style={{ fontSize: "18px" }}>
                    {translate("home3-3")}
                  </p>
                  <div className="upper-arrow-container">
                    <div
                      className={`inner-arrow ${selected === 0 ? "sel" : ""}`}
                    />
                    <div
                      className={`inner-arrow--1 ${selected === 1 ? "sel" : ""
                        }`}
                    />
                  </div>
                  <div className="inner-contact-tabs-container">
                    <div className="first-element" onClick={() =>  setSelected(0)}>
                      <div className={`inner-icon ${selected === 0 ? "selected" : ""   }`}/>
                    </div>

                    <div className="second-element" onClick={() => setSelected(1)}>
                      <div className={`inner-icon-1 ${selected === 1 ? "sell" : ""   }`} id={"second"} />
                    </div>
                  </div>
                  <div className="info-container" style={{ marginTop: "7%" }}>
                    <div className="inner-info-container">
                      <p style={{ fontSize: "15px" }}>
                        <b>{translate("clients7-3")}</b>
                      </p>
                      <p style={{ marginTop: "-3%" }}>
                        office@winkcharging.com
                      </p>
                    </div>
                    <div className="inner-info-container">
                      <p style={{ fontSize: "15px" }}>
                        <b>{translate("clients7-4")}</b>
                      </p>
                      <p style={{ marginTop: "-3%" }}>+359 2 980 10 59</p>
                    </div>
                  </div>
                  <div className="inner-info-container">
                    <p style={{ fontSize: "15px" }}>
                      <b>{translate("clients7-5")}</b>
                    </p>
                    <p style={{ marginTop: "-2%" }}>{translate("address1")}</p>
                  </div>
                </>
              );
            }}
          />
        ) : (
          <div className={`contact-component-container`}>
            <div className="left-container" style={{ width: "50%" }} />
            <div className="right-container" style={{ width: "50%" }}>
              <div
                className="inner-container"
                style={{ height: "85%", width: "90%" }}
              >
                <TitleComponent title={translate("clients7-1")} />
                <h2 style={{ textAlign: "left", fontSize: "22px" }}>
                  {translate("clients7-2")}
                </h2>
                <div className="flex-container">
                  <div className="form-input-wrapper">
                    <input
                      required
                      className="form-input"
                      type="text"
                      // value={fullName}
                      placeholder={translate("contactForm1")}
                      // onChange={(event) => setFullName(event.target.value)}
                    />
                  </div>
                </div>
                </div>
                <div className="button-right">
                  <div
                    className="button-container"
                    onClick={() => {
                      if (!loading) {
                        document.getElementById("error").style.display = "none";
                        setLoading(true);
                        setSuccess(false);
                        const payload = {
                          system: "wink",
                          // sender: fullName,
                          // company: company,
                          // email: email,
                          // phoneNumber: phone,
                          message: message,
                          onSuccess: () => {
                            setLoading(false);
                            setSuccess(true);
                            // setFullName("");
                            // setCompany("");
                            // setEmail("");
                            // setPhone("");
                            setMessage("");
                          },
                          onError: (error) => {
                            setLoading(false);
                            toast(error);
                          },
                        };
                        // sendEmail(payload);
                      } else
                        document.getElementById("error").style.display =
                          "block";
                    }}
                  >
                    {translate("send")}
                  </div>
                </div>
                <p style={{ display: "none" }} id="error">
                  {translate("contactForm8")}
                </p>
                {loading && <p>{loadingMessage}</p>}
                {success && <p>{translate("contactForm9")}</p>}
              </div>
            </div>
        )
      ) : (
        <div className="third-section">
          <TitleComponent title={translate("clients7-1")} fullLine mobile />
          <h6
            style={{
              width: "90%",
              fontSize: "17px",
              marginLeft: "5%",
              height: "3%",
              marginTop: "2.5%",
              lineHeight: "1.5em",
            }}
          >
            {translate("clients7-2")}
          </h6>
          <div className="mobile-tab">
            <div className="mobile-icon" />
          </div>
          <Section
            map={1}
            mobile={isMobileVersion}
            title={""}
            X={42.6626879801289}
            Y={23.38031102658445}
            height={70}
            renderContent={() => {
              return (
                <>
                  <div className="double-info" style={{ marginTop: "10%" }}>
                    <p className="info-title">
                      <b>{translate("clients7-3")}:</b>
                    </p>
                    <p className="second-field">office@winkcharging.com</p>
                  </div>
                  <div className="double-info">
                    <p className="info-title">
                      <b>{translate("clients7-4")}:</b>
                    </p>
                    <p className="second-field">+359 2 980 10 59</p>
                  </div>
                  <div className="double-info">
                    <p className="info-title">
                      <b>{translate("clients7-5")}:</b>
                    </p>
                    <p className="second-field">{translate("address1")}</p>
                  </div>
                </>
              );
            }}
          />
          <div className="mobile-tab">
            <div className="mobile-icon contact" />
          </div>
          <ContactComponent mobile={1} />
        </div>
      )} */}


    {isMobileVersion === 1 ?<>
      <Section
        map={selected === 0 ? 1 : 0}
        setSelectedLocation={setSelectedLocation}
        X={42.6624857000924}
        Y={23.380830984583703}
        locations={coordinates}
        locationData={mapData}
        image={""}
        title={translate("home3-1")}
        reversed
        mobile={isMobileVersion}
        titleMobile
        height={110}
        noMargin
        renderContent={()=>{
          return(
          <div className="overlay-container" style={{marginTop:"-5%"}}>
             {(Object.keys(currentLocation)?.length && Object.keys(selectedLocation)?.length) ?
              <div className="wrapper card">
                <div className="flex padding-bottom">
                  <img className="icon small pointer" src={require("../../assets/icons/close.svg")} onClick={() => setSelectedLocation([])} />
                  <h3>{currentLocation?.name}</h3>
                </div>
                <p className="subtitle padding-left">{currentLocation?.address}</p>

                <div className="text-wrapper padding-left">
                  <div className="flex">
                    <img className="icon" src={require("../../assets/icons/clock.svg")} />
                    <p className="bold">{`${currentLocation?.fromHour} - ${currentLocation?.toHour}`}</p>
                  </div>

                  <div className="flex">
                    <img className="icon" src={require("../../assets/icons/pin.svg")} />
                    <p className="bold">{currentLocation?.locationInfo}</p>
                  </div>

                  <div className="flex">
                    <img className="icon" src={require("../../assets/icons/half-battery.svg")} />
                    <p className="bold">{`${currentLocation?._stations?.length || 0} ${currentLocation?._stations?.length === 1 ? translate("station") : translate("stations")}`}</p>
                  </div>

                  <div className="flex">
                    <img className="icon" src={require("../../assets/icons/lightning.svg")} />
                    <p className="bold">{`${currentLocation?._stations?.length ? getPositionsCount(currentLocation?._stations) : 0} ${getPositionsCount(currentLocation?._stations) === 1 ? translate("position") : translate("positions")}`}</p>
                  </div>

                </div>
              </div>
              : <div className="wrapper">
                <p className={`text-info selected`} style={{fontSize:"16px"}}>
                  {translate("home3-2")}
                </p>
                <p className="text-info selected" style={{marginBottom:"7%",fontSize:"16px"}}> {translate("home3-3")} </p>
              </div>}
          </div>
          );
        }}
        />
      </>
    : 
        <Section
        title={translate("home3-1")}
        map={selected === 0 ? 1 : 0}
        setSelectedLocation={setSelectedLocation}
        X={42.6624857000924}
        Y={23.380830984583703}
        locations={coordinates}
        locationData={mapData}
        image={""}
        fullInner
        height={100}
        reversed

        // image = {`url(${require("../../assets/images/homepage-section-3-3.png")})`}
        // title={translate("home3-1")}
        // reversed
        renderContent={()=>{
          return(
          <div className="overlay-container">
              {(Object.keys(currentLocation)?.length && Object.keys(selectedLocation)?.length) ?
              <div className="wrapper card">

                <div className="flex padding-bottom">
                  <img className="icon small pointer" src={require("../../assets/icons/close.svg")} onClick={() => setSelectedLocation([])} />
                  <h3>{currentLocation?.name}</h3>
                </div>
                <p className="subtitle padding-left">{currentLocation?.address}</p>

                <div className="text-wrapper padding-left">
                  <div className="flex">
                    <img className="icon" src={require("../../assets/icons/clock.svg")} />
                    <p className="bold">{`${currentLocation?.fromHour} - ${currentLocation?.toHour}`}</p>
                  </div>

                  <div className="flex">
                    <img className="icon" src={require("../../assets/icons/pin.svg")} />
                    <p className="bold">{currentLocation?.locationInfo}</p>
                  </div>

                  <div className="flex">
                    <img className="icon" src={require("../../assets/icons/half-battery.svg")} />
                    <p className="bold">{`${currentLocation?._stations?.length || 0} ${currentLocation?._stations?.length === 1 ? translate("station") : translate("stations")}`}</p>
                  </div>

                  <div className="flex">
                    <img className="icon" src={require("../../assets/icons/lightning.svg")} />
                    <p className="bold">{`${currentLocation?._stations?.length ? getPositionsCount(currentLocation?._stations) : 0} ${getPositionsCount(currentLocation?._stations) === 1 ? translate("position") : translate("positions")}`}</p>
                  </div>

                </div>
                {/* <p className={`text-info selected`}>
                  {translate("home3-2")}
                </p>
                <p className="text-info selected"> {translate("home3-3")} </p> */}
              </div> :
              <div className="wrapper">
                <p className={`text-info selected`}>
                  {translate("home3-2")}
                </p>
                <p className="text-info selected"> {translate("home3-3")} </p>
              </div>}
          </div>
          );
        }}
        />
      }
      <Section
        title={translate("home4-1")}
        image={`url(${require("../../assets/images/homepage-section-4.png")})`}
        height={100}
        titleMargin={2}
        invert
        reversed
        titleMobile={isMobileVersion === 1 && true}
        backgroundBlack
        right={isMobileVersion === 0 && true}
        renderContent={()=>{
          return(
            <>
            {Array.from(Array(5)).map((x,i)=>{
              return(
                <div className="inner-charging-wrapper" style={{marginTop:"3%"}}>
                <div className={`flexx-container`}>
                  <div className="point-and-text">
                      <div className={`square`} style={{backgroundRepeat:"no-repeat",backgroundSize:"cover"}}/>
                      <h6 className={`tabb-title ${isMobileVersion === 1 && "mobile"}`}>{translate(`home4-${i+2}`)}</h6>
                  </div>
                </div>
              </div>
              )
            })}
            </>
            )
        }}
        />
        <div className={`fourth-section ${isMobileVersion === 1 ? "mobile" : ""}`}>
          <TitleComponent title={translate("home5-1")} fullwidth={isMobileVersion === 0 && true} fullLine={isMobileVersion && true} mobile={isMobileVersion && true}/>
          <div className = "blocks-container">
            <div className="block">
              <div className="block-image first"/>
               <p className="block-text">{translate("home5-2")}</p>
            </div>
            <div className="green-arrow"/>
            <div className="block">
              <div className="block-image second" style={{width:"97%",marginLeft:"2%"}}/>
               <p className="block-text">{translate("home5-3")}</p>
            </div>
            <div className="green-arrow"/>
            <div className="block">
              <div className="block-image third"/>
               <p className="block-text">{translate("home5-4")}</p>
            </div>
          </div>
        </div>

        <div className={`fifth-section ${isMobileVersion === 1 ? "mobile" : ""}`}>
          {isMobileVersion === 1 ? <>
            <div className="fifth-section-image"/>
            <TitleComponent title={translate("home6-1")} fullwidth={isMobileVersion === 0 && true} fullLine={isMobileVersion && true}/>
          </> : <>
          <TitleComponent title={translate("home6-1")} fullwidth={isMobileVersion === 0 && true} fullLine={isMobileVersion && true} mobile={isMobileVersion && true}/>
          <div className="fifth-section-image"/>
          </>}
          <div className="inner-container">
              <div className={`inner margin ${isMobileVersion === 1 && "mobile"}`}>{translate("home6-2")}</div>
              <div className={`inner margin ${isMobileVersion === 1 && "mobile"}`}>{translate("home6-3")}</div>
              <div className={`inner margin ${isMobileVersion === 1 && "mobile"} no-line`}>{translate("home6-4")}</div>
          </div>
        </div>

        <DownloadSection/>
        <QuestionsComponent bullets={true} />

  </div>)
}

export default Home;
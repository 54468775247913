import React from "react";
import { Route, Switch } from "react-router";
import { withRouter } from "react-router-dom";
import {Header,MobileHeader,Footer} from "../../components";
import {Home} from "../../screens/Home";
import {Charging} from "../../screens/Charging";
import {Clients} from "../../screens/Clients";
import {App} from "../../screens/App";
import {About} from "../../screens/About";
import {Article} from "../../screens/Article";

const Routes = () => {
  return (
    <div className="dashboard-wrap">
      <Header />
      <MobileHeader/>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/charging" component={Charging} />
        <Route exact path="/clients" component={Clients} />
        <Route exact path="/app" component={App}/>
        <Route exact path="/about" component={About}/>
        <Route  path="/article" component={Article}/>
      </Switch>
      <Footer/>
    </div>
  );
};

export default withRouter(Routes);

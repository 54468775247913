import React, { useState, useEffect } from "react";
import { TitleComponent } from "../../components";
import "./styles.scss";
import { translate } from "../../translations/localization";
import { connect } from "react-redux";
import { sendEmail } from "../../actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactComponent({ home, mobile, smallerTitle, selectedd, sendEmail }) {
  const [fullName, setFullName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    "Please wait a few seconds."
  );
  const [success, setSuccess] = useState(false);
  const [selected, setSelected] = useState(0);
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        let newLoadingMessage = loadingMessage + ".";
        if (newLoadingMessage === "Please wait a few seconds.....") {
          newLoadingMessage = "Please wait a few seconds.";
        }
        setLoadingMessage(newLoadingMessage);
      }, 500);
    }
  }, [loading, loadingMessage]);

  function validateEmail() {
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (regex.test(email) === false) {
      return false;
    }
    return true;
  }

  // function validatePhone() {
  //   var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  //   if (phoneno.test(phone) === false) return false;
  //   return true;
  // }

  return (
    <>
      {mobile === 1 ? (
        <div className={`contact-component-container ${home && "home"}`}>
          <div className="left-container mobile" />
          <div className="right-container" style={{ height: "110vh" }}>
            <div className="inner-container" style={{ marginTop: "-15%" }}>
              <div className="flex-container">
                <div className="form-input-wrapper">
                  <input
                    required
                    className="form-input"
                    type="text"
                    value={fullName}
                    placeholder={translate("contactForm1")}
                    onChange={(event) => setFullName(event.target.value)}
                  />
                </div>
                <div className="form-input-wrapper">
                  <input
                    required
                    className="form-input"
                    type="text"
                    value={company}
                    placeholder={translate("contactForm2")}
                    onChange={(event) => setCompany(event.target.value)}
                  />
                </div>
              </div>
              <div className="flex-container">
                <div className="form-input-wrapper">
                  <input
                    required
                    className="form-input"
                    type="text"
                    value={email}
                    placeholder={translate("contactForm3")}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                <div className="form-input-wrapper">
                  <input
                    required
                    className="form-input"
                    type="text"
                    value={phone}
                    placeholder={translate("contactForm4")}
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </div>
              </div>
              <div className="flex-container">
                <div className="form-textarea-wrapper">
                  <textarea
                    required
                    value={message}
                    placeholder={translate("contactForm5")}
                    onChange={(event) => setMessage(event.target.value)}
                  />
                </div>
              </div>

              <div
                className="button-container mobilesection"
                onClick={() => {
                  if (!loading && validateEmail()) {
                    document.getElementById("error").style.display = "none";
                    setLoading(true);
                    setSuccess(false);
                    const payload = {
                      system: "wink",
                      sender: fullName,
                      company: company,
                      email: email,
                      phoneNumber: phone,
                      message: message,
                      onSuccess: () => {
                        setLoading(false);
                        setSuccess(true);
                        setFullName("");
                        setCompany("");
                        setEmail("");
                        setPhone("");
                        setMessage("");
                      },
                      onError: (error) => {
                        setLoading(false);
                        toast(error);
                      },
                    };
                    sendEmail(payload);
                  } else
                    document.getElementById("error").style.display = "block";
                }}
              >
                {translate("send")}
              </div>
              <p style={{ display: "none" }} id="error">
                {translate("contactForm8")}
              </p>
              {loading && <p>{loadingMessage}</p>}
              {success && <p>{translate("contactForm9")}</p>}
            </div>
          </div>
        </div>
      ) : (
        <div className={`contact-component-container ${home && "home"}`}>
          <div className="left-container" />
          <div className="right-container">
            <div className="inner-container">
              <TitleComponent title={"REACH OUT"} removeLine={1} />
              <p style={{ textAlign: "left", marginBottom: "8%" }}>
                YOU HAVE QUESTIONS OR CAN'T FIND THE FEATURE YOU ARE LOOKING
                FOR?
              </p>
              <div className="upper-arrow-container">
                <div
                  className={`inner-arrow-1 ${selectedd === 1 ? "sel" : ""}`}
                />
              </div>
              <div className="inner-contact-tabs-container">
                <div
                  className="first-element"
                  onClick={() => {
                    setSelected(0);
                  }}
                >
                  <div
                    className={`inner-icon ${selected === 0 ? "selected" : ""}`}
                  />
                </div>
                <div
                  className="second-element"
                  onClick={() => {
                    setSelected(1);
                  }}
                >
                  <div className="inner-icon-1" />
                </div>
              </div>
              <div className="flex-container">
                <div className="form-input-wrapper">
                  <input
                    required
                    className="form-input"
                    type="text"
                    value={fullName}
                    placeholder={"Your name*"}
                    onChange={(event) => setFullName(event.target.value)}
                  />
                </div>
                <div className="form-input-wrapper">
                  <input
                    required
                    className="form-input"
                    type="text"
                    value={company}
                    placeholder={"Company's name"}
                    onChange={(event) => setCompany(event.target.value)}
                  />
                </div>
              </div>
              <div className="flex-container">
                <div className="form-input-wrapper">
                  <input
                    required
                    className="form-input"
                    type="text"
                    value={email}
                    placeholder={"Email*"}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                <div className="form-input-wrapper">
                  <input
                    required
                    className="form-input"
                    type="text"
                    value={phone}
                    placeholder={"Phone number*"}
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </div>
              </div>
              <div className="flex-end">
                <div className="flex-container">
                  <div className="form-textarea-wrapper">
                    <textarea
                      required
                      value={message}
                      placeholder={"Short message"}
                      onChange={(event) => setMessage(event.target.value)}
                    />
                  </div>
                </div>

                <div className="checkboxes">
                  <div className="first-checkbox">
                    <input
                      type="checkbox"
                      id="accept1"
                      class="checkbox"
                      onClick={() => {
                        window.open(
                          "https://immotech-web.s3.eu-central-1.amazonaws.com/images/privacy.pdf"
                        );
                      }}
                    />
                    <label for="accept1">
                      <u>AGREE WITH OUR COOKIE POLICY</u>{" "}
                    </label>
                  </div>
                  <div className="second-checkbox">
                    <input
                      type="checkbox"
                      id="accept2"
                      class="checkbox"
                      onClick={() => {
                        window.open(
                          "https://immotech-web.s3.eu-central-1.amazonaws.com/images/terms.pdf"
                        );
                      }}
                    />
                    <label for="accept2">
                      <u>AGREE WITH OUR PRIVACY POLICY</u>
                    </label>
                  </div>
                </div>
              </div>

              <div className="button-right">
                <div
                  className="button-container"
                  onClick={() => {
                    if (
                      !loading &&
                      validateEmail()
                    ) {
                      document.getElementById("error").style.display = "none";
                      setLoading(true);
                      setSuccess(false);
                      const payload = {
                        system: "wink",
                        sender: fullName,
                        company: company,
                        email: email,
                        phoneNumber: phone,
                        message: message,
                        onSuccess: () => {
                          setLoading(false);
                          setSuccess(true);
                          setFullName("");
                          setCompany("");
                          setEmail("");
                          setPhone("");
                          setMessage("");
                        },
                        onError: (error) => {
                          setLoading(false);
                          toast(error);
                        },
                      };
                      sendEmail(payload);
                    } else
                      document.getElementById("error").style.display = "block";
                  }}
                >
                  SEND
                </div>
              </div>
              <p style={{ display: "none" }} id="error">
                Some of the fields do not meet the requierements!
              </p>
              {loading && <p>{loadingMessage}</p>}
              {success && <p>Your asking was sent successfully, thank you!</p>}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const mapDispatchToProps = (dispatch) => ({
  sendEmail: (payload) => dispatch(sendEmail(payload)),
});
export default connect(null, mapDispatchToProps)(ContactComponent);

import { translate } from "../../translations/localization"
import React, { useState, useEffect } from "react";
import { QuestionsComponent, Section, TitleComponent } from "../../components";
import "./styles.scss";


const Charging = () => {
  const [selected, setSelected] = useState(0);
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="home-container">
      <Section
        title={translate("charging1-1")}
        image={`url(${require("../../assets/images/charging-section-1.png")})`}
        height={isMobileVersion === 1 && 130}
        titleMargin={2}
        titleMobile={isMobileVersion === 1 && true}
        renderContent={() => {
          return (
            <>
              <div className="inner-charging-wrapper">
                <div className={`flexx-container`}>
                  <div className="point-and-text">
                    <div className={`square`} />
                    <h6 className={`tabb-title`}><b>{translate("charging1-2")}</b></h6>
                  </div>
                </div>
                <p className={`${isMobileVersion === 1 ? 'text-info' : 'textt-info'}`}>
                  {translate("charging1-3")}
                </p>
              </div>
              <div className="inner-wrapper">
                <div className={`flexx-container`}>
                  <div className="point-and-text">
                    <div className={`square`} />
                    <h6 className={`tabb-title`}><b>{translate("charging1-4")}</b></h6>
                  </div>
                </div>
                <p className={`${isMobileVersion === 1 ? 'text-info' : 'textt-info'}`}>
                  {translate("charging1-5")}
                </p>
              </div>
            </>
          )
        }}
      />
      {isMobileVersion === 1 ?
        <div className="white-container">
          <TitleComponent title={translate("charging2-1")} mobile />
          <Section
            image={`url(${require("../../assets/images/charging-section-2-1.png")})`}
            height={100}
            title={""}
            backgroundWhite
            renderContent={() => {
              return (
                <div className="overlay-container">
                  <div className="wrapper" style={{ marginLeft: "3%" }}>
                    <div className={`flex-container ${"selected"}`} onClick={() => setSelected(0)}>
                      <div className="line-and-text">
                        <div className={`left-line selected`} />
                        <h6 className={`tab-title selected`}>{translate("charging2-2")}</h6>
                      </div>
                    </div>
                    <p className={`text-info selected`} style={{ fontSize: "16px" }}>
                      {translate("charging2-3")}
                    </p>
                  </div>
                </div>
              );
            }}
          />
          <Section
            image={`url(${require("../../assets/images/charging-section-2-2.png")})`}
            height={100}
            title={""}
            renderContent={() => {
              return (
                <div className="overlay-container">
                  <div className="wrapper" style={{ marginLeft: "3%" }}>
                    <div className={`flex-container ${"selected"}`} onClick={() => setSelected(1)}>
                      <div className="line-and-text">
                        <div className={`left-line selected`} />
                        <h6 className={`tab-title selected`}>{translate("charging2-4")}</h6>
                      </div>
                    </div>
                    <p className={`text-info selected`} style={{ fontSize: "16px" }}>
                      {translate("charging2-5")}
                    </p>
                  </div>
                </div>
              );
            }}
          />
        </div>
        :
        <Section
          image={selected === 0 ? `url(${require("../../assets/images/charging-section-2-1.png")})` : selected === 1 ? `url(${require("../../assets/images/charging-section-2-2.png")})` : ""}
          title={translate("charging2-1")}
          invert
          height={101.5}
          unsetPos
          right={isMobileVersion === 0 && true}
          renderContent={() => {
            return (
              <div className="overlay-container">
                <div className="wrapper">
                  <div className={`flex-container ${selected === 0 && "selected"}`} onClick={() => setSelected(0)}>
                    <div className="line-and-text">
                      <div className={`left-line ${selected === 0 && "selected"}`} />
                      <h6 className={`tab-title no-margin ${selected === 0 && "selected"}`}>{translate("charging2-2")}</h6>
                    </div>
                  </div>
                  <p className={`text-info ${selected === 0 && "selected"}`}>
                    {translate("charging2-3")}
                  </p>
                </div>
                <div className="wrapper">
                  <div className={`flex-container ${selected === 1 && "selected"}`} onClick={() => setSelected(1)}>
                    <div className="line-and-text">
                      <div className={`left-line ${selected === 1 && "selected"}`} />
                      <h6 className={`tab-title no-margin ${selected === 1 && "selected"}`}>{translate("charging2-4")}</h6>
                    </div>
                  </div>
                  <p className={`text-info ${selected === 1 && "selected"}`}>
                    {translate("charging2-5")}
                  </p>
                </div>
              </div>
            );
          }}
        />
      }
      <Section
        title={(translate("charging3-1"))}
        image={`url(${require("../../assets/images/charging-section-3.png")})`}
        titleMobile={isMobileVersion && true}
        height={isMobileVersion === 1 && 120}
        innerHeight={isMobileVersion !== 1 && 45}
        renderContent={() => {
          return (
            <div className="inner-content" style={{ height: "80%", marginTop: "5%" }}>
              <div style={{ width: isMobileVersion === 1 ? "95%" : "100%", height: isMobileVersion === 1 ? "45px" : "15%", borderRadius: "7px", backgroundColor: "#0F2532", display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: isMobileVersion === 1 && "2%" }}>
                <span className="start">{translate("charging3-2")}</span>
                <b style={{ marginRight: "2%", fontSize: isMobileVersion === 1 && "18px" }}>1.00лв.</b>
              </div>
              <p style={{ width: "100%", marginBottom: isMobileVersion === 1 && "5%" }}>{(translate("charging3-3"))}</p>
              <div style={{ width: isMobileVersion === 1 ? "95%" : "100%", height: isMobileVersion === 1 ? "45px" : "15%", borderRadius: "7px", backgroundColor: "#0F2532", display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: isMobileVersion === 1 && "2%" }}>
                <span className="start">{translate("charging3-4")}</span>
                <b style={{ marginRight: "2%", fontSize: isMobileVersion === 1 && "18px" }}>0.95 лв. / Квч.</b>
              </div>
              <p style={{ width: "100%" }}>{(translate("charging3-5"))}</p>
            </div>
          )
        }}
      />

      <QuestionsComponent bullets />

    </div>
  );
}

export default Charging;



import React from "react";
import { TitleComponent,Mapforgoogle } from ".././../components";
import "./styles.scss";

const Section = ({
  renderContent,
  invert,
  title,
  image,
  specialImage,
  selected,
  reversed,
  removeLine,
  contain,
  mobile,
  moveupinner,
  blended,
  opacity,
  fullblended,
  height,
  map,
  fullwidth,
  X,
  Y,
  backgroundWhite,
  backgroundBlack,
  fullLine,
  titleMobile,
  fullInner,
  column,
  innerHeight,
  right,
  maxHeight,
  marginTop,
  unsetPos,
  locations,
  locationData,
  setSelectedLocation,
  noMargin,
  noContent,
  maxHeightImage
}) => {
  var color;
  if (reversed) {
    color = invert ? "white" : "black";
  } else {
    color = invert ? "black" : "white";
  }


  return (
    <div className={`section-container ${column && "column"} ${invert && "inverted"}  ${right && "right"}
    ${backgroundWhite && "backgroundWhite"}  ${ mobile && "mobile" } ${ opacity && "opacity" } ${fullblended && "fullblended"} `}  style={{height:`${height}vh`,marginTop:`${marginTop}%`}}>

    {mobile === 1 ? 
    map === 1 ? <Mapforgoogle X={X} Y={Y} locationData={locationData} locations={locations} setSelectedLocation={setSelectedLocation}  center={{  lat: X, lng: Y }} mobile={1}/> : "" : 
    map === 1 ? <Mapforgoogle X={X} Y={Y} locationData={locationData} locations={locations} setSelectedLocation={setSelectedLocation}  center={{  lat: X, lng: Y }}/> : "" }  
      
    {specialImage ? (
        <div className="container special">
        </div>
      ) : image === "" ? "" : <div className={`container image ${contain && 'contain'} `} style={{ backgroundImage: image, backgroundPosition: unsetPos && `unset`, maxHeight: maxHeightImage && `${maxHeightImage}%` }} />}
     <div className={`container content ${color} ${moveupinner && 'moveupinner'} ${backgroundBlack && "backgroundBlack"}
     ${blended && "blended"} ${ opacity && "opacity" } ${noContent && 'no-content'}
     ${backgroundWhite && "backgroundWhite"}`} style={{maxHeight: maxHeight && `${maxHeight}%`}}>
      <div className={`container-inner ${moveupinner && 'moveupinner'} ${fullInner && "fullInner"} ${noMargin && "no-margin"}`} style={{height:innerHeight && `${innerHeight}%`}}>
      {title==="" ? "" : <TitleComponent title={title} removeLine={removeLine} leftfullwidth={fullwidth} fullLine={fullLine} mobile={titleMobile}/>}
      {renderContent && renderContent()}
      </div>
    </div> 
      
    </div>
   );
};

export default Section;
